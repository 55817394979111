import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { hideDialog } from 'store/reducers/dialogsSlice';
import { useQuilt, useRedirectAndScroll } from 'hooks';
import Header from '../../Header';
import { HeaderInfo } from './HeaderInfo';
import LineBlock from '../../LineBlock';
import { styleImageByType } from '../CreateMemorialQuiltStepThree/const';
import TributesAndCondolences from '../../TributesAndCondolences';
import { DialogBlocksProps } from './types';
import { AccessPermissionsTextEnum } from '../CreateMemorialQuiltStepTwo/const';
import stylesThree from '../CreateMemorialQuiltStepThree/styles.module.scss';
import styles from './styles.module.scss';
import { ReadMore } from 'components/ReadMore';

export const DialogBlocks: FC<DialogBlocksProps> = ({
  blocksProps,
  isViewPage,
  isTributes,
  editButtonStatus,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { redirectAndScroll } = useRedirectAndScroll({});
  const { id } = useParams();
  const { blocks: blocksUseQuilt } = useQuilt({ id: id || '' });
  const blocks = blocksProps ? blocksProps : blocksUseQuilt.getBlocksEditableQuilt();
  const blocksDialog = useAppSelector((state) => state.dialogs.dialogsState.blocks);
  const blockTopContent = blocks.find((block) => block.topContent);
  const isOnlyTopContent = blocks?.length === 3 && blockTopContent !== undefined && JSON.stringify(blocks[1].editorData) === '{}' && JSON.stringify(blocks[2].editorData) === '{}' && blocks[1].image === undefined && blocks[2].image === undefined;

  // handler for browser arrows
  useEffect(() => {
    const handlePopState = (event: any) => {
      if (blocksDialog) {
        dispatch(hideDialog('blocks'));
        if (window.history.length > 1) {
          navigate(1);
        } else {
          navigate('/');
        }
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const onBack = () => {
    dispatch(hideDialog('blocks'));
  };

  return (
    <>
      {!isViewPage && <Header />}

      <div
        className={isViewPage ? '' : styles.wrap}
        style={{
          background: 'center / cover no-repeat url("/img/general/background-preview.png")',
        }}
      >
        <div className={styles.headerImg}>
          {!isViewPage && <LineBlock />}
          <img
            className={styles.imgLine}
            src="/img/general/top-line-header-preview.png"
            alt="line"
          />
        </div>

        <div
          className="center"
          style={{
            width: '100%',
            overflowWrap: 'break-word',
          }}
        >
          <HeaderInfo />

          {blockTopContent && (
            <div className={`${stylesThree.blocks} ${styles.blocks_mt}`}>
              {blockTopContent?.topContent?.top && (
                <ReadMore
                  isTopContent
                  isFullText
                  content={blockTopContent.topContent?.top || ''}
                />
              )}
            </div>
          )}

          {blocks && blocks?.length > 0 && !isOnlyTopContent ? (
            <div className={stylesThree.blocks}>
              {blocks?.map((block, index) => {
                return (
                  <div key={index}>
                    {block.position === 'full' && block.image && 'file' in block.image ? (
                      <div className={stylesThree.editorBlock__imageFullNoCursor}>
                        <img
                          src={block.image.file}
                          alt="builderPicture"
                          className={`${styleImageByType[block.image.type]} ${styles.imageBorder}`}
                        />
                      </div>
                    ) : block.position === 'full' && typeof block.editorData === 'string' ? (
                      <ReadMore
                        content={block.editorData || ''}
                        isFullText
                      />
                    ) : null}

                    <div className={stylesThree.editorBlock2__top} key={block.id}>
                      <div data-side="left" className={styles.containerPart}>
                        {typeof block.editorData === 'object' && block.editorData.left?.value ? (
                          <ReadMore
                            content={block.editorData.left?.value || ''}
                          />
                        ) : block.image &&
                          'left' in block.image &&
                          block.image.left?.image?.file &&
                          block.image.left?.isRender ? (
                          <div className={stylesThree.editorBlock__imageNoCursor}>
                            <img
                              src={block.image.left.image.file}
                              alt="builderPicture"
                              className={`${styleImageByType[block.image.left.image.type]} ${styles.imageBorder} imageForPrint`}
                            />
                          </div>
                        ) : null}
                      </div>

                      <div data-side="right" className={styles.containerPart}>
                        {typeof block.editorData === 'object' && block.editorData.right?.value ? (
                          <ReadMore
                            content={block.editorData.right?.value || ''}
                          />
                        ) : block.image &&
                          'right' in block.image &&
                          block.image.right?.image?.file &&
                          block.image.right?.isRender ? (
                          <div className={stylesThree.editorBlock__imageRightNoCursor}>
                            <img
                              src={block.image.right.image.file || ''}
                              alt={'builderPicture'}
                              className={`${styleImageByType[block.image.right.image.type]} ${styles.imageBorder} imageForPrint`}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            !(blocks && blocks?.length === 0) && (
              <div style={{height: '45px'}}></div>
            )
          )} 
          
          {blocks && blocks?.length === 0 && (
            <div className={styles.emptyMessage}></div>
          )}
          
          {isTributes && (
            <Box mt={4} className="hiddenInPrint">
              <TributesAndCondolences />
            </Box>
          )}

          <div className={stylesThree.blocks}>
            <div className={`delimitierGrey ${styles.example__line}`}></div>
            {isViewPage ? (
              <div className={styles.button__block}>
                <button className="customButton" onClick={() => navigate(-1)}>
                  Back
                </button>
                {editButtonStatus === AccessPermissionsTextEnum.EDIT_IMAGES_MEMORIAL ? (
                  <button
                    className="customButton"
                    onClick={() => redirectAndScroll('/update/' + id + '?step=3')}
                  >
                    Edit
                  </button>
                ) : editButtonStatus === AccessPermissionsTextEnum.EDIT_IMAGES ? (
                  <button
                    className="customButton"
                    onClick={() => redirectAndScroll('/add-images/' + id)}
                  >
                    Edit Images
                  </button>
                ) : null}
              </div>
            ) : (
              <div className={styles.button__block}>
                <button className="customButton" onClick={onBack}>
                  EDIT MEMORIAL
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
