import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  CircularProgress,
  Collapse,
  Dialog,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import moment from 'moment';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { Loader } from 'components/Loader/Loader';
import { colors } from 'utilities';
import { AdminUserValue } from 'api/http-client/types';
import { confirmUser, makeAdmin, revokeAdmin } from 'api/http-client/queries/administration';
import { useAppDispatch, useAppSelector } from 'hooks';
import { setUsers } from 'store/reducers/adminUserSlice';
import { setOpen } from 'store/reducers/notifySlice';
import { ForgetPasswordForm } from 'pages/Login/ForgetPasswordForm';

import { LockAndDeleteBox } from '../LockAndDeleteBox';
import { IUserTableContent, SortBy, UserSortField, userSortFields, UserTableHeader } from './const';
import { QuiltTable } from '../QuiltTable';

import styles from './userTable.module.scss';
import { ValueOf } from 'common/generic/value-of';
import { QuiltSortField } from '../QuiltTable/const';

interface UserTableProps {
  visibleRows: AdminUserValue[];
  page: number;
  count: number;
  rowsPerPage: number;
  loading: boolean;
  currentUserId: string | null | undefined;
  handleChangePage: (newPage: number, sortBy: ValueOf<typeof SortBy>, sortField: ValueOf<typeof UserSortField>) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onUserRefetch: ({ sortBy, sortField }: { sortBy: ValueOf<typeof SortBy>; sortField: string }) => void;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  let checkedOrderBy = orderBy;
  if (checkedOrderBy === QuiltSortField.PUBLIC) {
    checkedOrderBy = 'public' as keyof T;
  }

  if (b[checkedOrderBy] < a[checkedOrderBy]) {
    return -1;
  }
  if (b[checkedOrderBy] > a[checkedOrderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: ValueOf<typeof SortBy>,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[] | IUserTableContent[] | AdminUserValue['quilts'],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]) as unknown as IUserTableContent[];
}

const tableCellStyle = {
  fontSize: {
    xs: 16,
    sm: 18,
    lg: 20,
  },
};

export const UserTable: React.FC<UserTableProps> = ({
  page,
  count,
  visibleRows,
  rowsPerPage,
  loading,
  currentUserId,
  handleChangePage,
  handleChangeRowsPerPage,
  onUserRefetch,
}) => {
  const [showUserDetails, setShowUserDetails] = useState({
    status: false,
    userId: '',
    email: '',
    ownerName: ''
  });
  const userTableContainer = useRef<HTMLDivElement | null>(null);
  const [showQuilts, setShowQuilts] = useState(false);

  const [userQuilts, setUserQuilts] = useState<AdminUserValue['quilts']>([]);
  const [userQuiltPage, setUserQuiltPage] = useState(0);
  const [userQuiltRowsPerPage, setUserQuiltRowsPerPage] = useState(5);

  const [userOrder, setUserOrder] = useState<ValueOf<typeof SortBy>>(SortBy.ASC);
  const [userOrderBy, setUserOrderBy] = useState<ValueOf<typeof UserSortField>>('email');

  const [quiltOrder, setQuiltOrder] = useState<ValueOf<typeof SortBy>>(SortBy.DESC);
  const [quiltOrderBy, setQuiltOrderBy] = useState<string>('dateCreated');

  const [changeRoleLoading, setChangeRoleLoading] = useState(false);
  const [dialog, setDialog] = useState(false);

  const dispatch = useAppDispatch();
  const adminUsersStore = useAppSelector((state) => state.adminUser);

  const userTableContent: IUserTableContent[] = useMemo(
    () =>
      visibleRows.map((row) => ({
        userId: row.userId,
        email: row.email,
        isAdmin: row.isAdmin,
        userFullName: row.userFullName,
        firstName: row.userFullName.split(' ')[0],
        lastName: row.userFullName.split(' ')[1],
        userStatus: row.status,
        dateCreated: moment(row.dateCreated || '').format('MM/DD/YYYY'),
        publishedQty: row.quiltsPublished,
        publicQty: row.quiltsPublic,
        privateQty: row.quilts.length - row.quiltsPublic,
        quiltsQty: row.quilts.length,
        lockedQty: row.quiltsLocked,
        deletedQty: row.quiltsDeleted,
        draftQty: row.quilts.length - row.quiltsPublished,
        locked: row.locked,
        deleted: row.deleted,
        emailConfirmed: row.emailConfirmed,
        reasonForDelete: row?.reasonForDelete || '',
        reasonForLock: row?.reasonForLock || '',
      })),
    [visibleRows]
  );

  useEffect(() => {
    if (showUserDetails.userId) {
      setUserQuilts(visibleRows.filter((user) => user.userId === showUserDetails.userId)[0].quilts);
    }
  }, [showUserDetails.userId, visibleRows]);

  useEffect(() => {
    setShowUserDetails({ status: false, userId: '', email: '', ownerName: '' });
    setShowQuilts(false);
  }, [loading]);

  const handleUserQuiltChangePage = (newPage: number) => {
    setUserQuiltPage(newPage);
  };

  const handleUserQuiltChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserQuiltRowsPerPage(parseInt(event.target.value, 10));
    setUserQuiltPage(0);
  };

  const onQuiltRefetch = ({ sortBy, sortField }: { sortBy: ValueOf<typeof SortBy>; sortField: string }) => {
    setQuiltOrder(sortBy);
    setQuiltOrderBy(sortField);
  };

  const visibleUserQuiltRows = useMemo(() => {
    const userQuiltsCoerced = userQuilts.map((quilt) => ({
      ...quilt,
      datePublished: quilt.datePublished ? quilt.datePublished : '',
    }));
    const sortedQuilts = stableSort(userQuiltsCoerced, getComparator(quiltOrder, quiltOrderBy));
    return sortedQuilts.slice(
      userQuiltPage * userQuiltRowsPerPage,
      userQuiltPage * userQuiltRowsPerPage + userQuiltRowsPerPage
    );
  }, [quiltOrder, quiltOrderBy, userQuiltPage, userQuiltRowsPerPage, userQuilts]);

  const makeAdminClick = (userId: string) => {
    const userIsAdmin = visibleRows.filter((user) => user.userId === userId)[0].isAdmin;
    setChangeRoleLoading(true);
    if (userIsAdmin) {
      revokeAdmin(userId)
        .then((res) => {
          dispatch(setOpen('User role changed successfully'));
          dispatch(
            setUsers(
              adminUsersStore.map((user) =>
                user.userId === userId ? { ...user, isAdmin: false } : user
              )
            )
          );
        })
        .catch((err) => {
          dispatch(setOpen(err.response.data.message));
        })
        .finally(() => {
          setChangeRoleLoading(false);
        });
      return;
    } else {
      makeAdmin(userId)
        .then((res) => {
          dispatch(setOpen('User role changed successfully'));
          dispatch(
            setUsers(
              adminUsersStore.map((user) =>
                user.userId === userId ? { ...user, isAdmin: true } : user
              )
            )
          );
        })
        .catch((err) => {
          dispatch(setOpen(err.response.data.message));
        })
        .finally(() => {
          setChangeRoleLoading(false);
        });
    }
  };

  const createSortHandler = (property: string) => () => {
    const isAsc = userOrderBy === property && userOrder === 'asc';
    onUserRefetch({ sortBy: isAsc ? 'desc' : 'asc', sortField: property });
    setUserOrder(isAsc ? 'desc' : 'asc');
    setUserOrderBy(property as ValueOf<typeof UserSortField>);
  };

  const handleVerifyUser = async (userId: string) => {
    setChangeRoleLoading(true);

    confirmUser({ userId })
      .then((res) => {
        dispatch(setOpen('User successfully verified'));
        dispatch(
          setUsers(
            adminUsersStore.map((user) =>
              user.userId === userId ? { ...user, emailConfirmed: true } : user
            )
          )
        );
      })
      .catch((err) => {
        dispatch(setOpen(err.response.data.message));
      })
      .finally(() => {
        setChangeRoleLoading(false);
      });
  };

  const scrollToLeft = () => {
    userTableContainer.current?.scrollTo({ left: 0, behavior: 'smooth' });
  };

  const getClassNameForStickyColumnCell = (idx: number) => {
    if (idx === 0) {
      return styles.firstStickyColumnCell;
    }
    if (idx === 1) {
      return styles.secondStickyColumnCell;
    }
    if (idx === 2) {
      return styles.thirdStickyColumnCell;
    }

    return '';
  }

  return (
    <section>
      <TableContainer 
        ref={userTableContainer} 
        sx={{ 
          mt: 6, 
          overflowY: 'hidden',
          overflowX: "scroll", 
          "&::-webkit-scrollbar": { height: "5px" }, 
          "&::-webkit-scrollbar-thumb": { backgroundColor: "#888", borderRadius: "4px" },
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="users table">
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={5}
                sx={{
                  fontSize: { xs: 16, sm: 20, lg: 24 },
                  color: colors.dark_blue,
                  fontWeight: 700,
                }}
              >
                User information
              </TableCell>
              <TableCell
                colSpan={7}
                sx={{
                  fontSize: { xs: 16, sm: 20, lg: 24 },
                  color: colors.dark_blue,
                  fontWeight: 700,
                }}
              >
                Quilt information
              </TableCell>
            </TableRow>
            <TableRow>
              {UserTableHeader.map((header, idx) => (
                <TableCell
                  key={header.id}
                  align={header.align}
                  className={getClassNameForStickyColumnCell(idx)}
                  sx={{
                    width: { xs: 'auto', lg: header.width },
                    fontSize: { xs: 16, sm: 20, lg: 24 },
                    color: colors.dark_blue,
                    fontWeight: 700,
                  }}
                >
                  {header.id === 'button' || !userSortFields.includes(header.id as ValueOf<typeof UserSortField>) ? (
                    header.label
                  ) : (
                    <TableSortLabel
                      active={userOrderBy === header.id}
                      direction={userOrderBy === header.id ? userOrder : 'asc'}
                      onClick={createSortHandler(header.id)}
                    >
                      {header.label}
                      {userOrderBy === header.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {userOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell align="center" colSpan={8} sx={{ minWidth: '250px', ...tableCellStyle }}>
                  <CircularProgress color="inherit" />
                </TableCell>
              </TableRow>
            ) : userTableContent?.length ? (
              userTableContent.map((row) => (
                <React.Fragment key={row.userId}>
                  <TableRow>
                    <TableCell 
                      align="center" 
                      className={styles.firstStickyColumnCell}
                      sx={tableCellStyle}>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                          showUserDetails.userId !== row.userId && scrollToLeft();

                          setShowUserDetails((prev) => ({
                            status: prev.userId === row.userId ? false : true,
                            userId: prev.userId === row.userId ? '' : row.userId,
                            email: prev.userId === row.userId ? '' : row.email,
                            ownerName: prev.userId === row.userId ? '' : `${row.firstName} ${row.lastName}`,
                          }));
                          setShowQuilts(false);
                        }}
                      >
                        {showUserDetails.status && showUserDetails.userId === row.userId ? (
                          <ArrowDropUpIcon fontSize="large" />
                        ) : (
                          <ArrowDropDownIcon fontSize="large" />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={styles.secondStickyColumnCell}
                      sx={tableCellStyle}
                    >
                      {row.firstName}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={styles.thirdStickyColumnCell}
                      sx={tableCellStyle}
                    >
                      {row.lastName}
                    </TableCell>
                    <TableCell align="center" sx={{ minWidth: '250px', ...tableCellStyle }}>
                      <Typography
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          fontWeight: 700,
                          fontSize: { xs: 16, sm: 18, lg: 20 },
                        }}
                      >
                        {row.email}
                        {row.isAdmin && (
                          <Box
                            component="span"
                            sx={{
                              px: 0.5,
                              border: '1px solid #26a3c5',
                              fontSize: 12,
                              color: colors.dark_blue,
                              fontWeight: 400,
                              whiteSpace: 'nowrap',
                            }}
                          >
                            Admin
                          </Box>
                        )}
                        {currentUserId === row.userId && (
                          <Box
                            component="span"
                            sx={{
                              px: 0.5,
                              border: '1px solid #26a3c5',
                              fontSize: 12,
                              color: colors.dark_blue,
                              fontWeight: 400,
                              whiteSpace: 'nowrap',
                            }}
                          >
                            You
                          </Box>
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" sx={{ minWidth: '250px', ...tableCellStyle }}>
                      {row.dateCreated}
                    </TableCell>
                    <TableCell align="center" sx={{ minWidth: '250px', ...tableCellStyle }}>
                      {row.userStatus}
                    </TableCell>

                    <TableCell align="center" sx={{ minWidth: '200px', ...tableCellStyle }}>
                      {row.quiltsQty}
                    </TableCell>
                    <TableCell align="center" sx={{ minWidth: '200px', ...tableCellStyle }}>
                      {row.publicQty}
                    </TableCell>
                    <TableCell align="center" sx={{ minWidth: '200px', ...tableCellStyle }}>
                      {row.privateQty}
                    </TableCell>
                    <TableCell align="center" sx={{ minWidth: '200px', ...tableCellStyle }}>
                      {row.publishedQty}
                    </TableCell>
                    <TableCell align="center" sx={{ minWidth: '200px', ...tableCellStyle }}>
                      {row.draftQty}
                    </TableCell>
                    <TableCell align="center" sx={{ minWidth: '200px', ...tableCellStyle }}>
                      {row.lockedQty}
                    </TableCell>
                    <TableCell align="center" sx={{ minWidth: '200px', ...tableCellStyle }}>
                      {row.deletedQty}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    style={{
                      visibility:
                        showUserDetails.status && showUserDetails.userId === row.userId
                          ? 'visible'
                          : 'collapse',
                    }}
                  >
                    <TableCell style={{ padding: 0, width: '100%' }} colSpan={8}>
                      <Collapse in={showUserDetails.status} timeout={1000}>
                        <div className={styles.makeAdmin_box}>
                          {currentUserId !== row.userId && (
                            <button
                              className={`customButton ${styles.adminBtn}`}
                              onClick={() => setDialog(true)}
                            >
                              Send Password Reset Link
                            </button>
                          )}
                          {
                            <button
                              className={`customButton ${styles.adminBtn}`}
                              onClick={() => handleVerifyUser(row.userId)}
                            >
                              Verify User
                            </button>
                          }
                          <button
                            className={`customButton ${styles.adminBtn}`}
                            onClick={() => makeAdminClick(row.userId)}
                            disabled={currentUserId === row.userId && row.isAdmin}
                          >
                            {row.isAdmin ? 'Revoke Admin' : 'Make Admin'}
                          </button>
                        </div>
                        {currentUserId !== row.userId ? (
                          <LockAndDeleteBox 
                            id={showUserDetails.userId} 
                            row={row} 
                            isUser={true} 
                          />
                        ) : (
                          <Box
                            sx={{ my: -3, display: 'flex', justifyContent: 'center', fontSize: 20 }}
                          >
                            You can't lock or delete yourself
                          </Box>
                        )}
                        <div className={styles.showQuilts_box}>
                          <button
                            className={`customButton`}
                            style={{ minWidth: '270px' }}
                            onClick={() => setShowQuilts(!showQuilts)}
                          >
                            {showQuilts ? 'Hide Quilts' : 'Show Quilts'}
                          </button>
                        </div>
                        <Collapse in={showQuilts} timeout={1000}>
                          <QuiltTable
                            visibleRows={
                              visibleUserQuiltRows as unknown as AdminUserValue['quilts']
                            }
                            page={userQuiltPage}
                            count={userQuilts.length}
                            rowsPerPage={userQuiltRowsPerPage}
                            loading={false}
                            userId={showUserDetails.userId}
                            ownerName={showUserDetails.ownerName}
                            handleChangePage={handleUserQuiltChangePage}
                            handleChangeRowsPerPage={handleUserQuiltChangeRowsPerPage}
                            onQuiltRefetch={onQuiltRefetch}
                          />
                        </Collapse>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={8} sx={{ minWidth: '250px', ...tableCellStyle }}>
                  No users to display
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {count > 5 && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, page) => handleChangePage(page, userOrder, userOrderBy)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      <Loader loading={changeRoleLoading} />
      <Dialog maxWidth="sm" fullWidth open={dialog} onClose={() => setDialog(!dialog)}>
        <ForgetPasswordForm
          setDialog={() => setDialog(false)}
          isAdmin={true}
          email={showUserDetails.email}
        />
      </Dialog>
    </section>
  );
};
