import styles from './styles.module.scss';

type Props = {
  image: File | null | undefined;
  filesImages: File[];
  imageRef: React.RefObject<HTMLImageElement>;
  imageIndex: React.MutableRefObject<number | null>;
  isMain?: boolean;
  onSetImage: (file: File) => void;
};

const DropZone: React.FC<Props> = ({
  image,
  filesImages,
  imageRef,
  imageIndex,
  isMain,
  onSetImage
}) => {
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (imageIndex.current !== null) {
      const file = filesImages[imageIndex.current];

      onSetImage(file);
      imageIndex.current = null;
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  }

  return (
    <div 
      className={styles.editorBlock__drag} 
      onDrop={handleDrop} 
      onDragOver={handleDragOver}
    >
      {image ? (
        <img
          ref={imageRef}
          src={URL.createObjectURL(image)}
          alt={image?.name}
          style={{
            display: 'block',
            maxWidth: '100%',
          }}
        />
      ) : (
        <div className={styles.editorBlock__dragIcon}>
          <img 
            src="/img/general/drag-drop.png" 
            alt="drag-drop" 
          />
          <span>
            {isMain ? (
              'Double click on an image from the album to add here. This will be the main quilt photo'
            ) : (
              'Double click on an image from the album to add here'
            )}
          </span>
        </div>
      )}
    </div>
  );
};

export { DropZone };
