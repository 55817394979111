import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { invitedUsersTableHeaders } from './constants';
import {
  inviteUserToQuilt,
  removeUserAccess,
} from 'api/http-client/queries/profileQueries';
import { useState } from 'react';
import { colors, notifyMessagesQuiltUpdateCreate } from 'utilities';
import { setOpen } from 'store/reducers/notifySlice';
import { useAppDispatch } from 'hooks';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { DefaultDialog } from 'components/DefaultDialog';
import { Loader } from 'components/Loader/Loader';
import styles from './styles.module.scss';
import { AdminUserQuilt } from 'api/http-client/types';
import { getStatusForContributor } from './helpers';

interface Props {
  quiltId: string;
  contributors: AdminUserQuilt['contributors'];
}

type DeleteDialogType = {
  selectedEmail: string | null;
  isOpen: boolean;
};

const tableCellStyle = {
  fontSize: { 
    xs: 16, 
    sm: 18, 
    lg: 20 
  }, 
}

const InvitedUsersTable: React.FC<Props> = ({ 
  quiltId,
  contributors 
}) => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [invitedUsers, setInvitedUsers] = useState<AdminUserQuilt['contributors']>(contributors);
  const [deleteDialog, setDeleteDialog] = useState<DeleteDialogType>({
    selectedEmail: null,
    isOpen: false,
  });

  const removeInvitedUser = (email: string) => {
    setLoading(true);

    removeUserAccess({
      id: quiltId,
      email,
      message: 'delete',
    })
      .then(() => {
        dispatch(setOpen(notifyMessagesQuiltUpdateCreate.two.remove));
        setInvitedUsers(prev => prev.filter(contributor => contributor.email !== email));
      })
      .catch((e) => {
        dispatch(setOpen(`${e.errors}`));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resendInvitedLinkUser = (email: string) => {
    setLoading(true);

    inviteUserToQuilt({
      email,
      id: quiltId || '',
    })
      .then(() => {
        dispatch(setOpen(notifyMessagesQuiltUpdateCreate.two.success));
      })
      .catch((e) => {
        dispatch(setOpen(e.errors));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {invitedUsers.length !== 0 ? (
        <TableContainer 
          sx={{
            overflowY: 'hidden',
            overflowX: "scroll", 
            "&::-webkit-scrollbar": { height: "5px" }, 
            "&::-webkit-scrollbar-thumb": { backgroundColor: "#888", borderRadius: "4px" },
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                {invitedUsersTableHeaders.map((header, index) => (
                  <TableCell
                    key={index}
                    align='center'
                    sx={{
                      ...tableCellStyle,
                      color: colors.dark_blue,
                      fontWeight: 700,
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {invitedUsers.map((user, idx) => {
                return (
                  <TableRow key={`${user.userId}-${idx}`}>
                    <TableCell
                      align="center"
                      sx={{
                        ...tableCellStyle,
                        minWidth: '200px'
                      }}
                    >
                      {getStatusForContributor(user.status)}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        ...tableCellStyle,
                        minWidth: '400px'
                      }}
                    >
                      <Box
                        component={'a'}
                        href={`mailto:${user.email}`}
                        sx={{
                          'fontWeight': 400,
                          'color': colors.blue,
                          'textDecoration': 'underline',
                          '&:hover': { 
                            color: colors.dark_blue 
                          },
                        }}
                      >
                        {user.email}
                      </Box>
                    </TableCell>
                    <TableCell sx={{ ...tableCellStyle, minWidth: '253px' }}>
                      <button
                        className={styles.resendInviteBtn}
                        type="button"
                        onClick={(e) => resendInvitedLinkUser(user.email)}
                      >
                        Resend Invite
                      </button>
                    </TableCell>
                    <TableCell
                      sx={{ 
                        ...tableCellStyle,
                        minWidth: '200px'
                      }}
                    >
                      <div
                        className={styles.addUser__addIcon}
                        onClick={() =>
                          setDeleteDialog({
                            selectedEmail: user.email,
                            isOpen: true,
                          })
                        }
                      >
                        <DeleteOutlineOutlinedIcon />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography sx={{ color: colors.dark_blue, fontSize: 18, fontWeight: 700 }}>
          No users found
        </Typography>
      )}
      <Loader loading={loading} />
      <DefaultDialog
        open={deleteDialog.isOpen}
        setDialog={() => setDeleteDialog((prev) => ({ ...prev, isOpen: false }))}
        title={''}
        text={`Remove invited user's access?`}
        confirmAction={() => {
          const { selectedEmail } = deleteDialog;
          setDeleteDialog({ selectedEmail: null, isOpen: false });
          removeInvitedUser(selectedEmail as string);
        }}
        confirmBtnText="Remove"
        cancelBtnText="Cancel"
      />
    </>
  );
};

export { InvitedUsersTable };
