import React, { FC } from 'react';
import { OptionsRowType, OptionsWrapEnum } from 'common/enums/carousel/carousel.enum';
import { QuiltsGroup } from 'common/types/quilts-group/quilts-group';
import { getSettingsRow } from 'helpers/carousel/get-settings-row';
import { CarouselItemType } from '../CarouselItemType/CarouselItemType';
import { QuiltsResponseMega } from 'api/http-client/types';
import { CarouselItemType1 } from '../CarouselItemType/CarouselItemType1';
import { CarouselItem } from '../CarouselItemType/CarouselItem';

interface CarouselItemTypeModel {
  items: QuiltsGroup[];
  isReverse?: boolean;
}

interface MainCarouseRowProps extends CarouselItemTypeModel {
  indexOfRenderRow: number;
  option: OptionsWrapEnum;
}

const MainCarouseRow: FC<MainCarouseRowProps> = ({
  indexOfRenderRow,
  items,
  option,
}) => {
  const carouselItems = items.map((item, index) => {
    const { settings } = getSettingsRow({
      rowId: indexOfRenderRow,
      option,
    });

    let carouselItem;

    if (settings[index] === 'type') {
      carouselItem = <CarouselItemType item={item as QuiltsResponseMega} />;
    } else if (settings[index] === OptionsRowType.Type1) {
      carouselItem = <CarouselItemType1 item={item as QuiltsResponseMega} />;
    } else {
      carouselItem = <CarouselItemType isStub />;
    }

    return <React.Fragment key={`${index}${item?.id}`}>{carouselItem}</React.Fragment>;
  });

  return <CarouselItem>{carouselItems}</CarouselItem>;
};

export default MainCarouseRow;
