import { FOOTER_LOGOS } from "./logos";
import { ArrowPropsModel } from "components/HomeCarousel/components/Arrows";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import '../FullFooter/footer.scss';
import { useTranslates } from "providers/translates";

const CustomLeftArrow: React.FC<ArrowPropsModel> = ({ onClick, ...rest }) => {
  return (
    <div className="angle-left slick-arrow" onClick={onClick}>
      <i className="fa fa-chevron-left"></i>
    </div>
  );
};

const CustomRightArrow: React.FC<ArrowPropsModel> = ({ onClick, ...rest }) => {
  return (
    <div className="angle-right slick-arrow" onClick={onClick}>
      <i className="fa fa-chevron-right"></i>
    </div>
  );
};

const slidesToShow = (screenWidth: number) => {
  if (screenWidth <= 420) return 1;
  if (screenWidth <= 700) return 2;
  if (screenWidth <= 900) return 3;
  return 3;
};

type Props = {
  isHomePage?: boolean;
}

const Partners: React.FC<Props> = ({
  isHomePage
}) => {
  const { translates } = useTranslates();
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    settings = {
      ...settings,
      slidesToShow: slidesToShow(width),
    };
  }, [width]);

  let settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow(document.documentElement.clientWidth),
    slidesToScroll: 1,
    nextArrow: <CustomRightArrow />,
    prevArrow: <CustomLeftArrow />,
  };

  return (
    <div className="partners center">
      {isHomePage && (
        <div
          style={{
            color: '#4fa6c6',
            fontStyle: 'italic',
            fontSize: '31px',
            fontWeight: 600,
            padding: '28px',
          }}
        >
          {translates?.footer?.partnersLabel || 'Partners'}
        </div>
      )}
      <div style={{ position: 'relative', padding: '28px 0', margin: '0 30px' }}>
        <Slider {...settings}>
          {FOOTER_LOGOS.map((footerImg, i) => (
            <div key={i} style={{ display: 'flex', alignItems: 'center' }}>
              <div className="carousel_item">
                <img
                  style={{
                    height: i === 0 ? '124px' : '65px',
                    maxWidth: '100%',
                    objectFit: 'contain',
                  }}
                  src={footerImg}
                  alt="logo"
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export { Partners };