import { FC, ReactElement, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import {
  Tooltip,
  styled,
  TooltipProps,
  Box,
  Button,
  Typography,
  tooltipClasses,
  Zoom,
  Fade,
  Stack,
  useMediaQuery,
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CloseIcon from "@mui/icons-material/Close";

import { breakpoints } from '../utilities/responsiveBreakpoints';
import { useTranslates } from '../providers/translates';
import { useRedirectAndScroll } from '../hooks';

interface WithTooltipProps {
  child: ReactElement<any, any>;
  url: string;
  name: string;
  id: number;
  dateOfBirth: number;
  dateOfPassing?: number;
}

const style = {
  position: 'absolute' as 'absolute',
  transform: 'translate(-50%, -50%)',
  width: 420,
  height: 480,
  background: '#24466a',
  boxShadow: 24,
  p: 1,
  outline: 'none',
};

const WithTooltip: FC<WithTooltipProps> = ({
  child,
  url,
  name,
  id,
  dateOfBirth,
  dateOfPassing
}) => {
  const isSmallScreen = useMediaQuery("(max-width:1024px)");
  const { onlyRedirect } = useRedirectAndScroll({});
  const { translates } = useTranslates();
  const [isOpen, setIsOpen] = useState(false);
  const timeoutId = useRef<NodeJS.Timeout | undefined>(undefined);

  const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 300,
    },
  });

  const onView = (id: number) => {
    onlyRedirect(`quilts/${id}/memorial`);
  };

  return (
    <CustomWidthTooltip
      onMouseEnter={() => {
        if (!isMobile) {
          setIsOpen(true);
        }
      }}
      onClick={() => {
        if (isMobile) {
          if (!isOpen) {
            clearTimeout(timeoutId.current);

            timeoutId.current = setTimeout(() => {
              setIsOpen(false);
            }, 4000);
          }

          setIsOpen(prev => !prev);
        }
      }}
      PopperProps={{
        modifiers: [
          {
            name: "preventOverflow",
            options: {
              boundary: "window",
            },
          },
          {
            name: "customPosition",
            enabled: isSmallScreen,
            phase: "write",
            fn: ({ state }) => {
              if (state.elements.popper) {
                state.elements.popper.style.top = "0px";
                state.elements.popper.style.right = "0px";
                state.elements.popper.style.bottom = "auto";
                state.elements.popper.style.left = "50%";
                state.elements.popper.style.transform = `translate3d(-50%, ${state.rects.reference.y + state.rects.reference.height}px, 0px)`;
              }
            },
          },
        ],
      }}
      sx={{
        "& .MuiTooltip-tooltip": {
          maxWidth: 'unset'
        },
      }}
      TransitionComponent={
        document.documentElement.clientWidth > breakpoints.mobile.max ? Zoom : Fade
      }
      open={isOpen}
      placement={document.documentElement.clientWidth <= breakpoints.mobile.max ? 'top' : 'bottom'}
      title={
        <Box
          sx={
            document.documentElement.clientWidth >= breakpoints.tablet.max
              ? style
              : { width: '100%' }
          }
          onMouseLeave={() => {
            if (!isMobile) {
              setIsOpen(false)
            }
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              padding: '5px 0',
            }}
          >
            <CloseIcon 
              sx={{
                cursor: 'pointer'
              }}
              onClick={() => setIsOpen(false)} 
            />
          </Box>
          <img
            src={url}
            alt={name}
            style={{
              width: '100%',
              height: '80%',
              padding: '3px',
              objectFit: 'cover',
            }}
          />

          <Stack 
            direction="row" 
            spacing={1} 
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Stack justifyContent="start" alignItems="start" mt={1} ml={1}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ textAlign: 'center', fontWeight: 700 }}
              >
                {name}
              </Typography>
              <Box sx={{ textAlign: 'center', position: 'relative' }}>
                <Typography component="span" id="modal-modal-description">
                  {dateOfBirth} - {dateOfPassing || 'present'}
                </Typography>
              </Box>
            </Stack>

            <Box
              style={{
                textDecoration: 'none',
                position: `${
                  document.documentElement.clientWidth <= 1024 ? 'static' : 'absolute'
                }`,
                right: '15px',
                bottom: '15px',
                background: '#57a0c1',
                color: 'white',
                borderRadius: '6px',
                padding: '0',
              }}
            >
              <Button
                sx={{
                  width: `${document.documentElement.clientWidth <= 1024 ? '100%' : 'inherit'}`,
                  color: 'white',
                  padding: '2px 8px',
                  paddingRight: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                }}
                onClick={() => onView(id)}
              >
                <Typography
                  component="span"
                  sx={{
                    color: 'white',
                    fontWeight: 700,
                    marginTop: '2px'
                  }}
                >
                  {translates?.carouselCard?.carouselCardBtnLabel || 'View'}
                </Typography>

                <ArrowRightIcon sx={{ fontSize: '34px', position: 'absolute', right: '-6px' }} />
              </Button>
            </Box>
          </Stack>
        </Box>
      }
    >
      {child}
    </CustomWidthTooltip>
  );
};

export default WithTooltip;
