import { httpClient } from '..';
import { TQuiltCreateUpdate } from '../types';

export const createQuilt = async (body: TQuiltCreateUpdate): Promise<number> => {
  const { data } = await httpClient.post('/quilts', {
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
  });
  return data.returned;
};

export const editQuilt = async (id: string, body: TQuiltCreateUpdate) => {
  const { data } = await httpClient.put(`/quilts/${id}`, {
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
  });
  return data;
};

// export const publicQuilt = async (id: string) => {
//   const { data } = await httpClient.put(`/quilts/${id}/public`);
//   return data.returned;
// };

// export const privateQuilt = async (id: string) => {
//   const { data } = await httpClient.put(`/quilts/${id}/private`);
//   return data.returned;
// };

// export const publishQuilt = async (id: string) => {
//   const { data } = await httpClient.put(`/quilts/${id}/publish`);
//   return data.returned;
// };

// export const unpublishQuilt = async (id: string) => {
//   const { data } = await httpClient.put(`/quilts/${id}/unpublish`);
//   return data.returned;
// };

export const uploadQuiltImg = async (id: string, image: File) => {
  const { data } = await httpClient.post(`/quilts/${id}/final-image`, {
    responseType: 'json',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: { image },
  });

  return data;
};

export const deleteQuiltImg = async (id: string) => {
  const { data } = await httpClient.delete(`/quilts/${id}/final-image`);

  return data;
};

export const publishDraft = async (id: string, isPublished: boolean) => {
  const { data } = isPublished
    ? await httpClient.put(`/quilts/${id}/unpublish`)
    : await httpClient.put(`/quilts/${id}/publish`);
  return data;
};

export const publicPrivate = async (id: string, isPublic: boolean) => {
  const { data } = isPublic
    ? await httpClient.put(`/quilts/${id}/private`)
    : await httpClient.put(`/quilts/${id}/public`);
  return data;
};

export const postPhotoToQuiltAlbum = async (id: string, image: File) => {
  const { data } = await httpClient.post(`/quilts/${id}/images`, {
    responseType: 'json',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: { image },
  });
  return data;
};

export const deletePhotoFromQuiltAlbum = async (id: string, name: string) => {
  const { data } = await httpClient.delete(`/quilts/${id}/images/${name}`);
  return data;
};
