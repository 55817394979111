// eslint-disable-next-line no-useless-escape
export const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const postalCodeRegex =
  /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
export const phoneNumberRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
export const passwordRegex = /(?=.*[0-9])(?=.*\W)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}/g;
export const maxImgSize = 12582912;
export const containOneDigitRegex = /(?=.*[0-9])/;
export const containOneSpecialCharRegex = /(?=.*\W)/;
export const containOneLowerCaseRegex = /(?=.*[a-z])/;
export const containOneUpperCaseRegex = /(?=.*[A-Z])/;

export const buttonStyle = {
  backgroundColor: 'black',
  color: '#66cc33',
  fontSize: 16,
};

export const notifyMessages = {
  quiltChanged: 'The quilt settings changed',
  accessAllowed: 'Access allowed',
  accessBlocked: 'Access blocked',
  quiltDeleted: 'The quilt has been deleted.',
  savedSettings: 'Settings have been saved.',
  passwordChanged: 'The password has been changed.',
  provideAnImage: 'Quilt Image must be provided',
  subscriptionSuccess: 'Thank you! You are subscribed to the newsletter.',
  subscriptionError: 'Unable to subscribe. Please try again later.',
  invalidEmail: 'Invalid email address',
  quiltPublicImageError:
    'For a quilt to be made public, it needs a main photo in Step 1. Please return to Step 1 to add a main photo and save your changes.',
  quiltViewHomePageError:
    'Viewing on the home page is not currently available. Please make sure your quilt is set to public and is published. Check to make sure you selected a main photo in Step 1 as this is also required to view your quilt on the home page in the Canadian Healing Quilt.',
  quiltPublicDraftError: 'The quilt cannot be made public. Please publish the quilt first',
};

export const notifyMessagesFileUpload = {
  tooBigFile: 'Too big file. Unable to upload an image. Maximum file size is 12Mb',
  unsupportedImageExtension: 'Unable to upload. Supported file extensions: png, jpeg, jpg, gif',
  imageUploaded: 'Image uploaded',
};

export const notifyMessagesQuiltMemorialUpdateCreate = {
  quiltCreatedSuccess: 'The quilt has been created',
  quiltCreatedError: 'Unable to create a quilt, please try again later',
  memorialUpdatedSuccess: 'Updated successfully',
  memorialUpdatedError: 'Unable to update a memorial, please try again later',
  quiltRequiredFieldsInfo: 'First name, last name, year of birth and year of death are mandatory.',
  quiltUpdatedSuccess: 'The quilt has been updated.',
  quiltUpdatedError: 'Unable to update a quilt, please try again later',
  quiltInvitedUserRestriction: 'You can add the photos only',
  memorialCreationInfo: 'To add a memorial first create a quilt',
  memorialEnableTributesInfo: 'You must have a saved memorial first!',
  memorialMakePublicInfo: 'The quilt is not public. Make your quilt public first!',
  memorialMakePublishInfo: 'The quilt is not published. Make your quilt published first!',
  validDateOfBirthOrDeath: 'Date of birth should be less than date of death',
  validateDayWithoutMonth: 'Please provide a month',
  validateMonthWithoutYear: 'Please provide a year',
  validateFutureDate: 'Date of birth or death cannot be in the future',
};

export const notifyMessagesQuiltUpdateCreate = {
  page: {
    problemMemorial: 'problem with the memorial!',
    accessMemorial: "You don't have the access to memorial!",
  },
  one: {
    image: 'You need to create the image!',
    imageDelete: 'You deleted the image successfully',
  },
  two: {
    noEmails: 'Please add an email address.',
    noMessage: 'You should write a message!',
    success: 'An invitation has been sent.',
    remove: 'User role removed',
    wrongEmails: (emails: string[]) => `Please enter a valid email address.`,
  },
  three: {},
  four: {},
};

export const httpMessages = {
  fetchPublicQuiltWithMemorialError:
    'The Memorial Quilt you have requested is currently not available. Please check back later.',
  sendEmailSuccess: 'Email has been sent',
  serverError: 'Server is not responding try again later',
  accountNotExist: 'Account does not exist',
  accountLocked: 'Account locked, cannot reset password. Please contact info@virtualhospice.ca for support.'
};

export const inputsSetting = {
  sxWidth: {
    width: '100%',
  },
};

export const keySessionStorageSaveRoute = 'SAVE_ROUTE';
