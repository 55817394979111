import { FC } from 'react';
import { Box, Stack, useMediaQuery } from '@mui/material';

import { useAuth } from 'providers/auth-provider';
import { useRedirectAndScroll } from 'hooks';
import HomeCarousel from 'components/HomeCarousel';

import styles from './styles.module.scss';

interface HomeProps {}

const Home: FC<HomeProps> = () => {
  const { redirectAndScroll } = useRedirectAndScroll({});
  const { isAuthorized, user } = useAuth();
  const mediaMax1350 = useMediaQuery('(max-width:1350px)');

  const handleRedirectHonour = () => (isAuthorized && !user?.isInvitedUser) ? redirectAndScroll('/create?step=1') : redirectAndScroll('/signup');

  return (
    <Box
      style={{
        background: 'center / cover no-repeat url("/img/general/background-preview.png")',
        height: '100%',
      }}
      pb={8}
    >
      <HomeCarousel />

      <div className="center" style={{ marginBottom: '24px' }}>
        <Box mt={7} width={mediaMax1350 ? 'auto' : 850}>
          <span className={styles.title}>A place to honour, <br/>a place to remember</span>
        </Box>

        <Stack
          direction={mediaMax1350 ? 'column' : 'row'}
          gap={mediaMax1350 ? 10 : 0}
          justifyContent="space-between"
          sx={{
            marginTop: '14px'
          }}
        >
          <Box width={mediaMax1350 ? '100%' : '60%'}>
            <div className={styles.text} style={{marginBottom: '50px'}}>
              <div>
                When someone important to you dies, the grief can feel 
                overwhelming. Finding ways to honour their memory can help.  
              </div>
              <div>
                Like a fabric quilt with varied pieces sewn with care, 
                the Canadian Healing Quilt stitches together life stories to provide comfort, healing, and serve as a lasting remembrance. 
              </div>
              <div>In Indigenous culture, star blankets and quilts offer protection in life and honour those who have died.</div>
              <div>
                Create a lasting tribute for someone important to you. Share stories, photos, cherished memories, and more in a 
                memorial quilt square that becomes part of the Canadian Healing Quilt. There is no cost to create or maintain a memorial. 
              </div>
              <div>
                Like a favourite quilt you wrap around yourself, the Canadian Healing Quilt can be opened at any time and shared with 
                those who need comfort.
              </div>
              <div>
                No one we remember is truly gone. Honour their memory and create a memorial quilt square. 
              </div>
            </div>
          </Box>

          <Stack
            spacing={9}
            justifyContent={mediaMax1350 ? 'center' : 'end'}
            alignItems={mediaMax1350 ? 'center' : ''}
          >
            <div className={styles.instruction} onClick={handleRedirectHonour}>
              <img
                className={styles.instructionImg}
                src="/img/general/dove-icon.png"
                alt="text-size"
                style={{
                  top: '-30px',
                }}
              />
              Honour someone with a memorial quilt square
            </div>

            <div className={styles.instruction} onClick={() => redirectAndScroll('/search')}>
              <img
                className={styles.instructionImg}
                src="/img/general/heart-icon.png"
                alt="text-size"
                style={{
                  top: '-22px',
                }}
              />
              Search for a person to share a condolence, memory, light a candle
            </div>

            <div className={styles.instruction}>
              <img className={styles.instructionImg} src="/img/general/candle-icon.png" alt="text-size" style={{
                top: '-50px'
              }}/>
              <Stack spacing={1} alignItems="center">
                <span>Send a wind mail</span>
                <img src="/img/general/question-icon.png" alt="text-size" width={24} height={24}/>
              </Stack>
            </div>

            <a href={'/resources'}>
              <div className={styles.instruction}>
                <img
                  className={styles.instructionImg}
                  src="/img/general/support-icon.png"
                  alt="text-size"
                  style={{
                    top: '-38px',
                    background: 'white',
                    borderRadius: '50%',
                  }}
                />
                Find grief support
              </div>
            </a>
          </Stack>
        </Stack>

        <div className={styles.textBottom}>
          <span>
            I am a poet...
            <br />
            Poets write on the breathe of a tree’s parched paper.
            <br />
            The tree is the ancestral knowledge keeper
            <br />
            Of all our relatives
            <br />
            Each branch extending to the universe
            <br />
            Breathing out prayer
            <br />
            Leaves open to the heavens.
            <br />
            This sapling
            <br />
            Is for those who’ve left,
            <br />
            For us still rooted to the earth.
            <br />
            • Louise Halfe – “Sky Dancer”
            <br />
          </span>
        </div>
      </div>
    </Box>
  );
};

export default Home;
