import adminQuiltSlice from './adminQuiltSlice';
import adminUserSlice from './adminUserSlice';
import authSlice from './authSlice';
import dialogsSlice from './dialogsSlice';
import notifySlice from './notifySlice';
import quiltSlice from './quiltSlice';
import usersSlice from './usersSlice';

const reducers = {
  auth: authSlice.reducer,
  dialogs: dialogsSlice.reducer,
  quilt: quiltSlice.reducer,
  adminUser: adminUserSlice.reducer,
  adminQuilt: adminQuiltSlice.reducer,
  users: usersSlice.reducer,
  notify: notifySlice.reducer,
};

export default reducers;
