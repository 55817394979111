import { heicToJpeg } from "./heic-to-jpeg";

const checkAndParseImages = async (files: FileList): Promise<File[]> => {
  const filesArray = Array.from(files);

  const parsedFiles: File[] = [];
  for (const file of filesArray) {
    if (file.type === 'image/heic') {
      const parsedFile = await heicToJpeg(file);
      if (parsedFile !== null) {
        parsedFiles.push(parsedFile);
      }

      continue;
    }

    parsedFiles.push(file);
  }

  return parsedFiles.filter(file => file !== null);
}

export { checkAndParseImages };
