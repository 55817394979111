import styles from './styles.module.scss';

const IconLinks: React.FC = () => {
  return (
    <div className={styles["icon-links"]}>
      <a
        className={styles["link-item"]}
        href="https://www.facebook.com/pages/Canadian-Virtual-Hospice/119170451459541?ref=ts"
        target="__blank"
      >
        <i className="fa fa-facebook-f"></i>
      </a>
      <a 
        className={styles["link-item"]} 
        href="https://twitter.com/VirtualHospice" 
        target="__blank"
      >
        <i className="fa fa-twitter"></i>
      </a>
      <a 
        className={styles["link-item"]}
        href="https://www.youtube.com/user/cvhcvcsp?ob=video-mustangbase"
        target="__blank"
      >
        <i className="fa fa-youtube"></i>
      </a>
      <a
        className={styles["link-item"]}
        href="https://www.linkedin.com/company/canadian-virtual-hospice"
        target="__blank"
      >
        <i className="fa fa-linkedin"></i>
      </a>
    </div>
  );
}

export { IconLinks };