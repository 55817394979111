import { ChangeEvent, forwardRef, useCallback, useEffect, useRef, useState, Ref } from 'react';
import { Select, SelectChangeEvent, SvgIcon } from '@mui/material';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { compareFiles, downloadImage, getName, getSelectOptions } from '../../../utilities';
import {
  aspectImageByType,
  optionsTypeImages,
  TypeImageEnum,
} from '../CreateMemorialQuiltStepThree/const';
import { ImageTypeModel } from '../CreateMemorialQuiltStepThree/model';

import { DefaultDialog } from '../../DefaultDialog';
import { accessImageDialog } from '../../../utilities/dialogs';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import './mobile-cropper.scss';

import styles from '../CreateMemorialQuiltStepThree/styles.module.scss';
import { useLocation, useParams } from 'react-router-dom';
import {
  deletePhotoFromQuiltAlbum,
  postPhotoToQuiltAlbum,
} from '../../../api/http-client/queries/quilts';
import { Loader } from '../../Loader/Loader';
import { useAppDispatch, useAppSelector, useRedirectAndScroll } from '../../../hooks';
import { setEditableQuiltState } from '../../../store/reducers/quiltSlice';
import { editUnpublishedQuilt } from '../../../api/http-client/queries/quiltQueries';
import { setOpen } from 'store/reducers/notifySlice';
import { TImage } from '../../../api/http-client/types';
import { EDITED_IMAGE } from '../constants/constants';
import { checkAndParseImages } from '../helpers/check-and-parse-images';
import { DropZone } from 'components/DropZone';
import { DraggableImage } from 'components/DraggableImage';

interface CreateImagesBlockProps {
  setImageBlock: (image: ImageTypeModel | null) => void;
  isAddImagesOnly?: boolean;
  isStepOne?: boolean;
  isMain?: boolean;
  imageName?: string | null;
  showAddImages?: boolean;
}

export const CreateImagesBlock = forwardRef<HTMLDivElement, CreateImagesBlockProps>(
  (
    { 
      setImageBlock, 
      isAddImagesOnly, 
      isStepOne, 
      isMain = false, 
      imageName, 
      showAddImages 
    },
    ref: Ref<HTMLDivElement>
  ) => {
    const { id } = useParams();
    const editableQuiltStore = useAppSelector((state) => state.quilt.editableQuilt);
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { onlyRedirect } = useRedirectAndScroll({});

    const [loading, setLoading] = useState(false);
    const [filesImages, setFilesImages] = useState<File[]>([]);

    const [currentFileImage, setCurrentFileImage] = useState<File | null>(() => null);
    const [originalImage, setOriginalImage] = useState<string | null>(null);

    const [currentCrop, setCurrentCrop] = useState<Cropper | null>(null);
    const [aspectRatio, setAspectRatio] = useState<TypeImageEnum>(
      isStepOne ? TypeImageEnum.SQUARE : TypeImageEnum.PORTRAIT
    );
    const [confirmChooseImageDialog, setConfirmChooseImageDialog] = useState<boolean>(false);
    const [showClearImagesDialog, setShowClearImagesDialog] = useState<boolean>(false);
    const [isNotAskAccessPermission, setIsNotAskAccessPermission] = useState<boolean>(false);
 
    const fileInputRef = useRef<HTMLInputElement>(null);
    const imageRef = useRef<HTMLImageElement>(null);
    const imageIndex = useRef<number | null>(null);

    useEffect(() => {
      (async () => {
        if (currentCrop !== null) {
          setCurrentFileImage(null);
          setCurrentCrop(null);
          setOriginalImage(null);

          currentCrop?.destroy();
        }

        if (!imageName) return;

        let imageUrl: string | undefined;

        if (isMain) {
          imageUrl = editableQuiltStore.album?.find((photo) =>
            photo.full.split('/').pop()?.endsWith(imageName)
          )?.full;
        } else {
          imageUrl = editableQuiltStore.album?.find(
            (photo) => photo.full.split('/').pop() === imageName
          )?.full;
        }

        if (!imageUrl) return;

        const downloadedImage = await downloadImage(imageUrl);
        setOriginalImage(imageName);
        setCurrentFileImage(downloadedImage);
      })();
    }, [imageName, showAddImages]);

    // init filesImages;
    // TODO fix invoking initImages (many times) in builder
    useEffect(() => {
      if (editableQuiltStore?.album) initImages(editableQuiltStore.album);
    }, [editableQuiltStore?.album]);

    useEffect(() => {
      if (!currentCrop) return;

      currentCrop.setAspectRatio(aspectImageByType[aspectRatio]);
    }, [aspectRatio]);

    useEffect(() => {
      if (!imageRef.current) return;

      if (currentCrop && currentFileImage) {
        currentCrop.replace(URL.createObjectURL(currentFileImage));
      } else {
        setCurrentCrop(
          new Cropper(imageRef.current, {
            aspectRatio: aspectImageByType[aspectRatio],
          })
        );
      }
    }, [imageRef.current, currentFileImage]);

    const initImages = async (data: TImage[]) => {
      const promisesFiles = data.map((item) => downloadImage(item.full));
      const resultData = await Promise.all(promisesFiles);

      if (promisesFiles)
        setFilesImages(resultData.filter((image) => !image.name.includes(EDITED_IMAGE)));
    };

    const isAddImages = () => location.pathname.includes('add-images');

    // need for opening a window for pictures
    const openImageChoosing = () => {
      setConfirmChooseImageDialog(false);
      setIsNotAskAccessPermission(true);
      fileInputRef?.current && fileInputRef?.current?.click();
    };

    const changeImages = async (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;

      if (!files || !id) return;

      // check the same images
      const filesDataUnique: File[] = [];
      const parsedImages = await checkAndParseImages(files);

      if (filesImages.length === 0) {
        filesDataUnique.push(...parsedImages);
      } else {
        for (const newFile of parsedImages) {
          let isNewFile = true;

          for (const wasFile of filesImages) {
            const isSameFile = await compareFiles(newFile, wasFile);

            if (isSameFile) {
              dispatch(
                setOpen(`You already have the same picture! You can\`t add ${newFile.name}`)
              );
              isNewFile = false;
              break;
            }
          }

          if (isNewFile) filesDataUnique.push(newFile);
        }
      }

      setLoading(true);

      if (filesDataUnique.length > 0) {
        setLoading(true);
        await Promise.all(filesDataUnique.map(async (file) => postPhotoToQuiltAlbum(id, file)));
        setLoading(false);

        updateEditableStore();

        if (isAddImagesOnly) {
          const name = getName({
            firstName: editableQuiltStore?.firstName,
            lastName: editableQuiltStore?.lastName,
          });

          // notify for only this page
          dispatch(setOpen(`Your images have been successfully added to ${name} album`));
        }
      }

      setLoading(false);

      setFilesImages((state) => {
        return [...state, ...filesDataUnique];
      });
    };

    const resetImages = () => {
      if (!id) return;

      setLoading(true);

      Promise.all(
        filesImages.map(async (fileImage) => await deletePhotoFromQuiltAlbum(id, fileImage.name))
      ).finally(() => {
        setLoading(false);
      });

      setFilesImages([]);
      setShowClearImagesDialog(false);
    };

    const deleteFileImage = async (fileIndex: number) => {
      if (!id) return;

      setLoading(true);

      // for local delete
      setFilesImages((state) =>
        state ? state.filter((item, index) => index !== fileIndex) : state
      );
      await deletePhotoFromQuiltAlbum(id, filesImages[fileIndex].name);

      setLoading(false);

      updateEditableStore();
    };

    const updateEditableStore = async () => {
      if (!id) return;

      setLoading(true);
      const data = await editUnpublishedQuilt(id);
      setLoading(false);

      // need for synchronization store with new albums
      dispatch(setEditableQuiltState(data));
    };

    // select
    const getOptionsTypeImages = useCallback(
      (typeImage: string) => {
        return getSelectOptions(optionsTypeImages, typeImage);
      },
      [currentFileImage]
    );

    const changeCurrentFileImageType = (event: SelectChangeEvent<TypeImageEnum>) => {
      setAspectRatio(event.target.value as TypeImageEnum);
    };

    // image actions
    const changeImage = () => {
      setCurrentFileImage(null);
      setCurrentCrop(null);
      setOriginalImage(null);

      currentCrop?.destroy();
    };

    const cancelImage = () => {
      changeImage();
      setImageBlock(null);
    };

    const continueImage = () => {
      if (!currentCrop) return;

      setImageBlock({
        file: currentCrop.getCroppedCanvas().toDataURL('image/png'),
        type: aspectRatio,
        originalImage: originalImage ?? '',
      });

      changeImage();
    };

    const rotateImage = () => {
      currentCrop && currentCrop.rotate(45);
    };

    const handleSetImage = (file: File) => {
      if (file) {
        setCurrentFileImage(file);
        setOriginalImage(file.name);
      }
    };

    return (
      <>
        <DefaultDialog
          open={showClearImagesDialog}
          setDialog={setShowClearImagesDialog}
          title={''}
          text={
            'Are you sure you want to clear the album? All photos will be deleted and this cannot be undone.'
          }
          confirmAction={resetImages}
        />

        <div
          ref={ref}
          className={styles.editorBlock1}
          style={{
            ...(isAddImagesOnly && {
              height: '750px',
            }),
          }}
        >
          <div className={styles.editorBlock1__header}>
            <div className={styles.editorBlock1__headerLeft}>
              <input
                type="file"
                id="fileInputAddImage"
                multiple
                ref={fileInputRef}
                onChange={changeImages}
                accept="image/png, image/jpeg, image/heic"
                style={{
                  display: 'none',
                }}
              />
              <button
                className={`${styles.editorBlock1__button}`}
                onClick={() =>
                  isNotAskAccessPermission ? openImageChoosing() : setConfirmChooseImageDialog(true)
                }
              >
                add images
              </button>
              <span className={styles.isDesktop}>Add your images to the album</span>
            </div>

            <button
              className={`${styles.editorBlock1__button}`}
              onClick={() => (isAddImagesOnly ? onlyRedirect('/') : cancelImage())}
            >
              exit
            </button>
          </div>
          <span className={styles.isMobile}>Add your images to the album</span>

          <div className={styles.editorBlock1__middle}>
            <div
              className={styles.editorBlock1__middleLeft}
              style={{
                ...(isAddImagesOnly && {
                  minWidth: '100%',
                }),
              }}
            >
              {filesImages &&
                filesImages.map((file, index) => {
                  return (
                    <div key={index} className={styles.imgWrapper}>
                      <DraggableImage 
                        file={file}
                        index={index}
                        onSetImage={handleSetImage}
                        imageIndex={imageIndex}
                      />
                      {!isAddImages() && (
                        <div
                          className={styles.actionButtons}
                          onClick={() => deleteFileImage(index)}
                        >
                          <div className={styles.details__addPhotoActionSvg}>
                            <DeleteOutlineIcon />
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>

            {!isAddImagesOnly && (
              <>
                <div className={`${styles.editorBlock1__bottomButtonLeft} ${styles.isMobile}`}>
                  <button
                    className={`${styles.editorBlock1__button}`}
                    style={{
                      color: '#d85656',
                    }}
                    onClick={() => setShowClearImagesDialog(true)}
                  >
                    clear album
                  </button>
                </div>
                <DropZone
                  image={currentFileImage}
                  imageRef={imageRef}
                  filesImages={filesImages}
                  imageIndex={imageIndex}
                  onSetImage={handleSetImage}
                  isMain={isMain}
                />
              </>
            )}
          </div>

          {!isAddImagesOnly && (
            <div className={styles.editorBlock1__bottom}>
              <div className={`${styles.editorBlock1__bottomButtonLeft} ${styles.isDesktop}`}>
                <button
                  className={`${styles.editorBlock1__button}`}
                  style={{
                    color: '#d85656',
                  }}
                  onClick={() => setShowClearImagesDialog(true)}
                >
                  clear album
                </button>
              </div>
              <div className={styles.editorBlock1__bottomButtonRight}>
                <div
                  className={`${styles.editorBlock1__bottomButton_choice} ${isStepOne ? '' : styles.column_reverse}`}
                >
                  <div className={styles.rotateAndSelectWrapper}>
                    <button
                      className={`${styles.editorBlock1__button}`}
                      style={{
                        padding: '6px 10px 3px',
                      }}
                    >
                      <SettingsBackupRestoreIcon
                        onClick={rotateImage}
                        className={`${styles.editorBlock1__bottomSvg}`}
                      ></SettingsBackupRestoreIcon>
                    </button>
                    {!isStepOne && (
                      <Select
                        className="customSelect"
                        IconComponent={() => (
                          <SvgIcon fontSize="large" component={ArrowDropDownIcon} />
                        )}
                        inputProps={{
                          sx: {
                            background: 'white',
                            padding: '5px 15px',
                            fontSize: '20px !important',
                          },
                        }}
                        sx={{
                          minWidth: { xs: 'auto', sm: 200 },
                          minHeight: 39,
                          fieldset: {
                            border: '1px solid #26a3c5',
                          },
                        }}
                        MenuProps={{
                          className: 'customSelectMenu',
                        }}
                        value={aspectRatio}
                        onChange={changeCurrentFileImageType}
                      >
                        {getOptionsTypeImages(aspectRatio || '')}
                      </Select>
                    )}
                  </div>
                  <button className={`${styles.editorBlock1__button}`} onClick={changeImage}>
                    Clear image
                  </button>
                </div>

                <div className={styles.editorBlock1__bottomButton}>
                  <button className={`${styles.editorBlock1__button}`} onClick={cancelImage}>
                    Cancel
                  </button>
                  <button className={`${styles.editorBlock1__button}`} onClick={continueImage}>
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}

          <DefaultDialog
            open={confirmChooseImageDialog}
            setDialog={setConfirmChooseImageDialog}
            title={accessImageDialog.title}
            text={accessImageDialog.text}
            confirmAction={openImageChoosing}
          ></DefaultDialog>
        </div>

        <Loader loading={loading} />
      </>
    );
  }
);
