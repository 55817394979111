import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styles from './styles.module.scss';
import { useRedirectAndScroll } from 'hooks';
import { Partners } from 'components/Partners';

const committeeArray = [
  {
    name: 'Elder Albert McLeod',
  },
  {
    name: 'Judy Cook',
  },
  {
    name: 'Jennifer Gurke',
  },
  {
    name: 'Louise Halfe',
  },
  {
    name: 'Carolyn Moar',
  },
  {
    name: 'Shahina Siddiqui',
  },
  {
    name: 'Jeroline Smith'
  }
];


const AboutPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { onlyScroll } = useRedirectAndScroll({});

  useEffect(() => {
    const scrollTo = searchParams?.get('visible');
    if (scrollTo) {
      onlyScroll(scrollTo);
    }
  }, []);

  const legalNoticesLink = (
    <a
      className={styles.customLink}
      href="https://www.virtualhospice.ca/en_US/Main+Site+Navigation/Right+Footer+Navigation/Legal+Notices.aspx"
      target="_blank"
      rel="noreferrer"
    >
      Legal Notices / Privacy Policy
    </a>
  );

  return (
    <section className="center">
      <h1 className={styles.titleH1}>About the Canadian Healing Quilt</h1>
      <h2 className={styles.titleH2}>The idea</h2>
      <p className={styles.bodyText}>
        During the Covid-19 pandemic Elder Albert McLeod, a member of the Nisichawayasihk Cree Nation, approached 
        the Canadian Virtual Hospice about creating an online space to honour those who had died. He was very concerned 
        that with the growing number of delayed memorials and ceremonies, many people who died during Covid might not 
        be honoured when gathering restrictions were lifted. He envisioned a place where individuals and families could 
        come together online to create a lasting tribute to those who died and to support their healing journey. Drawing 
        from Indigenous culture, he saw a quilt as a universal symbol of comfort and honour. 
      </p>
      <h2 className={styles.titleH2}>The purpose of the Canadian Healing Quilt</h2>
      <p className={styles.bodyText}>
        The Canadian Healing Quilt brings to life Elder Albert’s vision for an online space to remember and honour those who have died. 
      </p>
      <p className={styles.bodyText}>
        Healing Quilt.ca,
      </p>
      <ul className='colorNavy'>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>Is a place where people living in Canada, and Canadians living around the world, can create memorials for people who have died.
        </li>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>Enables an unlimited number of people to collaborate on a memorial.
        </li>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>Is free. There is no cost to create or maintain a memorial.
        </li>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>A lasting place to visit, remember and honour.
        </li>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>Allows friends, colleagues and others to share a condolence, a tribute or light a candle in remembrance.
        </li>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>Honours Indigenous traditions and the importance that quilts and blankets have in life’s journeys.
        </li>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>Protects your privacy. Your contact information will never be shared.
        </li>
      </ul>
      <p className={styles.bodyText}>
        Many people have shared with us that due to geography or other reasons, they don’t have a “place” to visit someone who has died. 
        That a “place” would help them in their grief. We hope HealingQuilt.ca can be that place. 
      </p>
      <h2 className={styles.titleH2}>Partners</h2>
      <p className={styles.bodyText}>
        The Canadian Virtual Hospice is grateful to the Winnipeg Foundation for their investment to develop the Canadian Healing Quilt 
        and to Palliative Manitoba for their support of this project.
      </p>

      <Partners />
      <h2 className={styles.titleH2}>Advisory committee members</h2>
      <div className={`mt-30 ${styles.committee__block}`}>
        {committeeArray.map((item, index) => (
          <div key={index} className='colorNavy'>
            <p className={styles.committee__name}>{item.name}</p>
          </div>
        ))}
      </div>

      <h2 className={styles.titleH2}>We welcome your feedback</h2>
      <p className={styles.bodyText}>
        We hope you’ll take some time to share your experience, whether creating or viewing a memorial or adding a tribute or condolence. 
        We also appreciate hearing how the quilt has impacted you. Share your feedback, questions, or suggestions by emailing{' '} 
        <a 
          className={styles.customLink}
          href="mailto:info@virtualhospice.ca"
        >
          info@virtualhospice.ca
        </a>
        .
      </p>

      <h2 id='notice' className={styles.titleH2}>Canadian Healing Quilt Privacy and Use Notice</h2>
      <p className={styles.bodyText}>Last Updated: September 2024</p>
      <p className={styles.bodyText}>
        The Canadian Healing Quilt is operated by the Canadian Virtual Hospice, a division 
        of the International Centre for Dignity and Palliative Care, a registered charity.
      </p>
      <p className={styles.bodyText}>
        By using the Canadian Healing Quilt you are agreeing to the{' '}
        {legalNoticesLink}
        .
      </p>
      <p className={styles.bodyText}>By using the Canadian Healing Quilt, you also confirm the following:</p>
      <ul className="colorNavy">
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>if you create a Healing Quilt, that you are or have the 
          permission of the administrator or representative of the person you are commemorating;
        </li>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>if you post to a Healing Quilt, that you have the right 
          to post the photos and contents you are posting to a Healing Quilt; and
        </li>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>your use of the Canadian Healing Quilt will not be exploitive, 
          obscene, harmful, threatening, abusive, harassing, hateful, defamatory, sexually explicit or pornographic, 
          violent, inflammatory, or discriminatory based on race, sex, religion, nationality, disability, sexual orientation, 
          or age or other such legally prohibited ground or be otherwise objectionable, such determination to be made in 
          Canadian Virtual Hospice’s sole discretion.
        </li>
      </ul>
      <p className={styles.bodyText}>
        For more information, please see the{' '}
        {legalNoticesLink}
        .
      </p>
      <p className={styles.bodyText}>
        If you have any questions about the Canadian Healing Quilt or the privacy practices of Canadian Virtual Hospice, please contact{' '}
        <a 
          href="mailto:info@virtualhospice.ca?subject=I have questions about the Canadian Healing Quilt"
          className={styles.customLink}
        >
          info@virtualhospice.ca
        </a>
        .
      </p>
    </section>
  );
};

export default AboutPage;
