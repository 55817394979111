import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { AddUsers, InvitedUsers } from '../components';
import { useAppSelector, useRedirectAndScroll } from '../../../hooks';
import styles from './styles.module.scss';
import { CreateMemorialSteps } from '../CreateMemorialSteps';
import { STEP_TWO_SNAPSHOT_KEY } from '../constants/constants';

interface CreateMemorialQuiltStepTwoProps {
  onSetIsStepOneUpdated?: () => void;
  isActiveStep: number;
  steps: {
    index: number;
    title: string;
  }[];
}

export const CreateMemorialQuiltStepTwo: React.FC<CreateMemorialQuiltStepTwoProps> = ({
  isActiveStep,
  steps,
  onSetIsStepOneUpdated
}) => {
  const { id } = useParams();
  const location = useLocation();
  const editableQuilt = useAppSelector((state) => state.quilt.editableQuilt);
  const { redirectAndScroll } = useRedirectAndScroll({});

  const continueAction = () => {
    localStorage.removeItem(STEP_TWO_SNAPSHOT_KEY);
    redirectAndScroll(`${location.pathname}?step=3`);
  };

  useEffect(() => {
    onSetIsStepOneUpdated && onSetIsStepOneUpdated();
  }, []);

  return (
    <div className="center">
      <div className={styles.title}>
        <span>Memorial quilt square for </span>
        <span>
          {editableQuilt?.firstName || ''} {editableQuilt?.lastName || ''}
        </span>
      </div>

      <div className={styles.inviteOther}>
        <h2 className="defaultTitle">Invite others to contribute</h2>
      </div>

      {id ? (
        <Box mt={6}>
          <AddUsers
            pageName={`${editableQuilt?.firstName || ''} ${editableQuilt?.lastName || ''}`}
            id={id}
          />
        </Box>
      ) : (
        <></>
      )}

      <div className={styles.invitedUsers}>
        <Box>{id && <InvitedUsers idQuilt={id}></InvitedUsers>}</Box>
      </div>

      <div className={`delimitierGrey ${styles['separator-gap']}`}></div>

      <div className={styles.actions}>
        <div className={styles.actions__right}>
          <button className="customButton" onClick={continueAction}>
            CONTINUE
          </button>
        </div>
        <CreateMemorialSteps 
          isActiveStep={isActiveStep} 
          steps={steps} 
        />
      </div>
    </div>
  );
};
