type Props = {
  file: File;
  index: number;
  dropZone?: HTMLDivElement;
  imageIndex: React.MutableRefObject<number | null>;
  onSetImage: (file: File) => void;
};

const DraggableImage: React.FC<Props> = ({
  file, 
  index,
  imageIndex,
  onSetImage
}) => {
  const imageDragStart = () => {
    imageIndex.current = index;
  };

  const handleDoubleClickImage = () => {
    onSetImage(file);
  };

  return (
    <img
      onDragStart={imageDragStart}
      src={URL.createObjectURL(file)}
      alt={file.name}
      key={index}
      draggable={true}
      onDoubleClick={handleDoubleClickImage}
    />
  );
};

export { DraggableImage };
