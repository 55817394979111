import { useRedirectAndScroll } from "hooks";
import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useParams, useSearchParams } from "react-router-dom";

type Props = {
  isActiveStep: number;
  steps: {
    index: number;
    title: string;
  }[];
}

const CreateMemorialSteps: React.FC<Props> = ({
  isActiveStep,
  steps
}) => {
  const { onlyRedirect } = useRedirectAndScroll({});
  const [canChangeStep, setCanChangeStep] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();

  useEffect(() => {
    let step = searchParams.get('step');

    if (!step) {
      setSearchParams({
        step: '1',
      });
    }

    setCanChangeStep(!!id);
  }, [id, isActiveStep, searchParams, setSearchParams]);

  const getIsActive = (index: number): string => (isActiveStep >= index ? styles.active : '');

  const changeStep = (step: number) => {
    canChangeStep && onlyRedirect(`/update/${id}?step=${step}`);
  };

  return (
    <div className={styles.steps}>
      {steps.map((step) => {
        return (
          <div className={styles.step} key={step.index} onClick={() => changeStep(step.index)}>
            <div className={styles.stepCircle}>
              <div className={`${styles.stepCircle__inside} ${getIsActive(step.index)}`}>
                {step.index}
              </div>
            </div>

            <span className={`${styles.stepCircle__text} colorNavy`}>{step.title}</span>
          </div>
        );
      })}
    </div>
  );
}

export { CreateMemorialSteps };
