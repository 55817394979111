import { FC, ReactElement } from 'react';

import styles from './styles.module.scss';
import WithTooltip from 'hoc/WithToolTip';

interface WithTooltipProps {
  id: number;
  url: string;
  name: string;
  dateOfBirth: number;
  dateOfPassing?: number;
  disabled?: boolean;
  borderWidth?: number;
  styles?: Record<string, string>;
  children: ReactElement;
}

const WithTooltipWrap: FC<WithTooltipProps> = ({
  id,
  url,
  name,
  dateOfBirth,
  dateOfPassing,
  children,
}) => {
  if (!url)
    return (
      <div
        className={styles.quilt__container}
        style={{
          border: 0,
        }}
      ></div>
    );

  return (
    <WithTooltip
      child={children}
      url={url}
      name={name}
      id={id}
      dateOfBirth={dateOfBirth}
      dateOfPassing={dateOfPassing}
    />
  );
};

export default WithTooltipWrap;
