import { Base64WithName } from "../types";

export function base64ToBlob(base64: string, mimeType = 'image/png'): Blob {
    const binaryString = atob(base64.replace(/^data:.*?;base64,/, '').trim());
    const byteArray = Uint8Array.from(binaryString, char => char.charCodeAt(0));

    return new Blob([byteArray], { type: mimeType });
}

export const base64ToFIle = (base64: string, fileName: string, mimeType = 'image/png') => {
    const blob = base64ToBlob(base64, mimeType);
    
    return new File([blob], fileName, { type: mimeType });
}

export const getFilesFromBase64 = (images: Base64WithName[]) => {
    const arr = [];

    for (let i = 0; i < images.length; i++) {
        arr.push(base64ToFIle(images[i].data, images[i].name));
    }

    return arr;
}
