import React from 'react';
import {
  Box,
  Collapse,
  Divider,
  FormControlLabel,
  IconButton,
  Popover,
  Stack,
  Switch,
  TableCell,
  TableRow,
  useMediaQuery,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';

import { TRemoveAccess, TShareQuilt, TUserQuilt } from 'api/http-client/profileTypes';
import { useTranslates } from 'providers/translates';
import { useRedirectAndScroll } from 'hooks';

import { SharedDialog } from 'components/ShadedDialog/SaredDialog';
import { AddUsers, InvitedUsers } from 'components/CreateMemorialQuiltComponents/components';
import { DeleteButton, EditButton, ViewButton, InviteButton } from './TableButtons';

import userProfileStyles from '../styles.module.scss';
import styles from './styles.module.scss';
import { DefaultDialog } from '../../../components/DefaultDialog';

export const QuiltsTableRow: React.FC<{
  userEmail: string;
  quilt: TUserQuilt;
  publicUnpublic: (quilt: TUserQuilt) => void;
  publishUnpublish: (quilt: TUserQuilt) => void;
  removeAccess: (body: TRemoveAccess) => void;
  shareQuilt: (body: TShareQuilt) => void;
  deleteQuilt: (id: string) => void;
  rowStyle: string;
}> = ({
  userEmail,
  quilt,
  publicUnpublic,
  publishUnpublish,
  shareQuilt,
  deleteQuilt,
  rowStyle,
}) => {
  const [collapse, setCollapse] = React.useState(false);
  const [shareDialog, setShareDialog] = React.useState(false);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const { translates } = useTranslates();
  const { redirectAndScroll } = useRedirectAndScroll({});

  const matches_1400 = useMediaQuery('(min-width: 1400px)');
  const matches_900 = useMediaQuery('(min-width: 900px)');
  const matches_600 = useMediaQuery('(max-width: 600px)');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <TableRow className={rowStyle}>
        <TableCell align="left" width={100} className={userProfileStyles.userProfileBodyTableCell}>
          <Box
            sx={{
              width: { xs: 55, md: 80 },
              height: { xs: 55, md: 80 },
            }}
          >
            <img
              className={styles.imgTable}
              src={quilt.finalImage ? quilt.finalImage.full : 'img/general/bg-2.jpg'}
              alt="img"
            />
          </Box>
          {!matches_900 && (
            <Box className={styles.text}>
              <span>{quilt.name}</span>
            </Box>
          )}
        </TableCell>
        {matches_900 && (
          <TableCell
            align="left"
            className={`${styles.text} ${userProfileStyles.userProfileBodyTableCell}`}
          >
            <span>{quilt.name}</span>
          </TableCell>
        )}
        <TableCell align="left" className={userProfileStyles.userProfileBodyTableCell}>
          <span className={styles.text} style={{ ...(quilt.isPublic ? { opacity: 0.5 } : {}) }}>
            {' '}
            Private{' '}
          </span>
          <FormControlLabel
            disabled={quilt.locked}
            control={
              <Switch
                checked={quilt.isPublic}
                onChange={() => publicUnpublic(quilt)}
                size={matches_600 ? 'small' : 'medium'}
              />
            }
            label={''}
            className={styles.text}
            sx={{
              margin: 0,
              marginTop: -1,
            }}
          />
          <span className={styles.text} style={{ ...(!quilt.isPublic ? { opacity: 0.5 } : {}) }}>
            {' '}
            Public{' '}
          </span>
        </TableCell>

        <TableCell align="left" className={userProfileStyles.userProfileBodyTableCell}>
          <span className={styles.text} style={{ ...(quilt.isPublished ? { opacity: 0.5 } : {}) }}>
            {' '}
            Draft{' '}
          </span>
          <FormControlLabel
            disabled={quilt.locked}
            control={
              <Switch
                checked={quilt.isPublished}
                onChange={() => publishUnpublish(quilt)}
                size={matches_600 ? 'small' : 'medium'}
              />
            }
            label={''}
            className={styles.text}
            sx={{
              margin: 0,
              marginTop: -1,
            }}
          />
          <span className={styles.text} style={{ ...(!quilt.isPublished ? { opacity: 0.5 } : {}) }}>
            {' '}
            Published{' '}
          </span>
        </TableCell>
        {matches_1400 && (
          <>
            <TableCell style={{ padding: 5 }} align="left">
              <ViewButton
                viewButtonClick={() => redirectAndScroll('/quilts/' + quilt.id + '/memorial')}
                isLocked={quilt.locked}
              />
            </TableCell>

            <TableCell style={{ padding: 5 }} align="left">
              <EditButton
                editButtonClick={() => redirectAndScroll('/update/' + quilt.id + '?step=1')}
                isLocked={quilt.locked}
              />
            </TableCell>

            <TableCell style={{ padding: 5 }} align="left">
              <DeleteButton
                deleteButtonClick={() => setDeleteDialog(!deleteDialog)}
                isLocked={quilt.locked}
              />
            </TableCell>

            <TableCell style={{ padding: 5 }} align="left">
              <InviteButton setCollapse={() => setCollapse(!collapse)} isLocked={quilt.locked} />
            </TableCell>
          </>
        )}
        {!matches_1400 && (
          <TableCell
            align={matches_600 ? 'center' : 'right'}
            className={userProfileStyles.userProfileBodyTableCell}
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={handleClick}
              sx={{ padding: '0' }}
            >
              <MoreHorizIcon fontSize="large" />
            </IconButton>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Box sx={{ px: 2, py: 1 }} onClick={() => setAnchorEl(null)}>
                <ViewButton
                  viewButtonClick={() => redirectAndScroll('/quilts/' + quilt.id + '/memorial')}
                  isLocked={quilt.locked}
                />
                <Divider />
                <EditButton
                  editButtonClick={() => redirectAndScroll('/update/' + quilt.id + '?step=1')}
                  isLocked={quilt.locked}
                />
                <Divider />
                <DeleteButton
                  deleteButtonClick={() => setDeleteDialog(!deleteDialog)}
                  isLocked={quilt.locked}
                />
                <Divider />
                <InviteButton setCollapse={() => setCollapse(!collapse)} isLocked={quilt.locked} />
              </Box>
            </Popover>
          </TableCell>
        )}
      </TableRow>

      <TableRow style={{ visibility: collapse ? 'visible' : 'collapse' }}>
        <TableCell style={{ padding: 0 }} colSpan={4}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseIcon fontSize="large" onClick={() => setCollapse(!collapse)} color="action" />
          </Box>
          <Collapse in={collapse} timeout={1000}>
            {quilt?.id ? (
              <Stack spacing={{ xs: 3, sm: 9 }} mb={{ xs: 2, sm: 6 }} mt={1}>
                {(quilt?.contributors.length > 0 || quilt?.notClaimedInvitations.length > 0) && (
                  <InvitedUsers idQuilt={quilt.id} />
                )}
                <AddUsers pageName={`${quilt.name}`} id={quilt.id}></AddUsers>
              </Stack>
            ) : (
              <></>
            )}
          </Collapse>
        </TableCell>
      </TableRow>

      <SharedDialog
        id={quilt.id}
        userEmail={userEmail}
        setShareDialog={setShareDialog}
        shareDialog={shareDialog}
        shareQuilt={shareQuilt}
      />

      <DefaultDialog
        open={deleteDialog}
        setDialog={() => setDeleteDialog(!deleteDialog)}
        title={''}
        text={'Are you sure you want to delete this quilt?'}
        confirmAction={() => {
          deleteQuilt(quilt.id);
          setDeleteDialog(!deleteDialog);
        }}
      />
    </>
  );
};
