import { FC, useMemo } from 'react';
import { useCarouselHome } from 'hooks/useCarouselHome';
import { Loader } from 'components/Loader/Loader';
import { CustomLeftArrow, CustomRightArrow } from './components/Arrows';
import { QuiltsResponseMega } from '../../api/http-client/types';
import { QuiltsGroup } from 'common/types/quilts-group/quilts-group';
import MainCarouseRow from './components/Row/MainCarouseRow';
import styles from './styles.module.scss';
import Slider from 'react-slick';

const HomeCarousel: FC = () => {
  const { data, loading, settingData, option } = useCarouselHome();

  const groupsRows = useMemo(() => {
    if (!data) return [];

    if (data.length <= settingData.rowLimit) {
      return [data];
    }

    let group: (QuiltsResponseMega | null)[][] = [];
    const total = data?.reduce(
      (total, item) => {
        group.push(item);

        if (group.length === settingData.rowLimit) {
          total.push(group);
          group = [];
        }

        return total;
      },
      [] as QuiltsGroup[][][]
    ) || [];

    if (group.length !== 0) {
      total.push(group);
    }

    return total;
  }, [data]);

  const pages = groupsRows.map((group, index) => {
    return (
      <div 
        className={styles.slider__container} 
        key={index}
      >
        <div className={styles.slider__wrap}>
          <div className={styles.slider__content}>
            {group?.map((items, index) => {
              return (
                <MainCarouseRow
                  option={option}
                  key={index}
                  items={items}
                  indexOfRenderRow={index}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className={styles.container__wrap}>
      <Slider 
        dots={false} 
        arrows={true} 
        infinite={true}
        speed={1000}
        slidesToScroll={1}
        nextArrow={<CustomRightArrow />}
        prevArrow={<CustomLeftArrow />}
      >
        {pages}
      </Slider>
      <Loader loading={loading} />
    </div>
  );
};

export default HomeCarousel;
