import { Base64WithName } from "../types";

export const fileToBase64 = async (file: File): Promise<Base64WithName> => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => res({name: file.name, data: reader.result as string});
    reader.onerror = (error) => rej(error);
  });
}

export const getBase64Array = async (files: File[]): Promise<Base64WithName[]> => {
  const arr = [];

  for (let i = 0; i < files.length; i++) {
    arr.push(fileToBase64(files[i]));
  }

  return Promise.all(arr) as Promise<Base64WithName[]>;
}
