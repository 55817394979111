import { FC, useState, useEffect } from 'react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { AddUsersInputsModel } from '../CreateMemorialQuiltStepTwo/model';
import { notifyMessagesQuiltUpdateCreate } from '../../../utilities';
import { AccessPermissionsTextEnum } from '../CreateMemorialQuiltStepTwo/const';
import {
  getUserQuilts,
  inviteUserToQuilt,
  removeUserAccess,
} from '../../../api/http-client/queries/profileQueries';
import { Loader } from '../../Loader/Loader';
import { setOpen } from 'store/reducers/notifySlice';
import { ContributorNoInvitedModel } from 'api/http-client/profileTypes';
import { useAppDispatch, useAppSelector } from 'hooks';
import { setIsNeedUpdateUsers } from 'store/reducers/usersSlice';

import styles from '../CreateMemorialQuiltStepTwo/styles.module.scss';
import { DefaultDialog } from 'components/DefaultDialog';

interface InvitedUsersProps {
  idQuilt: string;
  isSmallBlock?: boolean;
}

type DeleteDialogType = {
  selectedEmail: string | null;
  isOpen: boolean;
};

export const InvitedUsers: FC<InvitedUsersProps> = ({ idQuilt, isSmallBlock }) => {
  const dispatch = useAppDispatch();
  const isNeedUpdate = useAppSelector((state) => state.users.isNeedUpdate);

  const [loading, setLoading] = useState(false);
  const [invitedUsers, setInvitedUsers] = useState<AddUsersInputsModel[]>([]);
  const [noInvitedUsers, setNoInvitedUsers] = useState<ContributorNoInvitedModel[]>([]);
  const [deleteDialog, setDeleteDialog] = useState<DeleteDialogType>({
    selectedEmail: null,
    isOpen: false,
  });

  useEffect(() => {
    if (!idQuilt) return;

    if (isNeedUpdate) {
      getUserQuiltsSetInvitedUsers();

      dispatch(setIsNeedUpdateUsers(false));
    } else {
      getUserQuiltsSetInvitedUsers();
    }
  }, [isNeedUpdate]);

  const removeInvitedUser = (email: string) => {
    setLoading(true);

    removeUserAccess({
      id: idQuilt,
      email,
      message: 'delete',
    })
      .then(() => {
        dispatch(setOpen(notifyMessagesQuiltUpdateCreate.two.remove));
        getUserQuiltsSetInvitedUsers();
      })
      .catch((e) => {
        dispatch(setOpen(`${e.errors}`));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resendInvitedLinkUser = (email: string) => {
    setLoading(true);

    inviteUserToQuilt({
      email,
      id: idQuilt || '',
    })
      .then(() => {
        dispatch(setOpen(notifyMessagesQuiltUpdateCreate.two.success));
      })
      .catch((e) => {
        dispatch(setOpen(e.errors));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getUserQuiltsSetInvitedUsers = () => {
    getUserQuilts().then((data) => {
      for (const item of data) {
        if (+item.id === +idQuilt) {
          setInvitedUsers(item.contributors);
          setNoInvitedUsers(item.notClaimedInvitations);
          break;
        }
      }
    });
  };

  return invitedUsers.length || noInvitedUsers.length ? (
    <div className={styles.invitedUsers__blocks}>
      {invitedUsers.length ? (
        <div className={`${styles.invitedUsers__blocks} ${isSmallBlock ? styles.small : ''}`}>
          <p className={styles.invitedUsers__blockTitle}>Invited Users</p>
          {invitedUsers
            .filter(
              (invitedUserFilter) => invitedUserFilter.access !== AccessPermissionsTextEnum.NO_EDIT
            )
            .map((invitedUser, index) => {
              return (
                <div className={styles.invitedUsers__block} key={index}>
                  <div className={styles.invitedUsers__blockLeft}>
                    <a
                      href={`mailto:${invitedUser.email}`}
                      className={styles.invitedUsers__blockText}
                    >
                      {invitedUser.email}
                    </a>
                  </div>
                  <div className={styles.invitedUsers__blockRight}>
                    <button
                      className={`customButton ${styles.addUser__select}`}
                      type="button"
                      onClick={(e) => resendInvitedLinkUser(invitedUser.email)}
                    >
                      Resend Invite Link
                    </button>
                    <div className={styles.addUser__right}>
                      <div
                        className={styles.addUser__addIcon}
                        onClick={() => {
                          setDeleteDialog({
                            selectedEmail: invitedUser.email,
                            isOpen: true,
                          })
                        }}
                      >
                        <DeleteOutlineOutlinedIcon />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        <></>
      )}
      {noInvitedUsers.length && !styles.small ? (
        <div className={styles.invitedUsers__blocks}>
          <p className={styles.invitedUsers__blockTitle}>Didn't accept the invitation</p>
          {noInvitedUsers
            .filter(
              (noInvitedUser) => `${noInvitedUser.access}` !== AccessPermissionsTextEnum.NO_EDIT
            )
            .map((noInvitedUser, index) => {
              return (
                <div className={styles.invitedUsers__block} key={index}>
                  <div className={styles.invitedUsers__blockLeft}>
                    <a
                      href={`mailto:${noInvitedUser.email}`}
                      className={styles.invitedUsers__blockText}
                    >
                      {noInvitedUser.email}
                    </a>
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        <></>
      )}
      <Loader loading={loading} />
      <DefaultDialog
        open={deleteDialog.isOpen}
        setDialog={() => setDeleteDialog((prev) => ({ ...prev, isOpen: false }))}
        title={''}
        text={`Remove invited user's access?`}
        confirmAction={() => {
          const { selectedEmail } = deleteDialog;
          setDeleteDialog({ selectedEmail: null, isOpen: false });
          removeInvitedUser(selectedEmail as string);
        }}
        confirmBtnText="Remove"
        cancelBtnText="Cancel"
      />
    </div>
  ) : (
    <></>
  );
};
