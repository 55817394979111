import { TArtificialElement } from './types';

export const getArtificialBlockSetting = (
  html: string, 
  isFullText: boolean
): TArtificialElement => {
  const fullTextWidth = window.screen.width - 96;
  const halfTextWidth = (fullTextWidth - 50) / 2;

  const tempDiv = document.createElement('div');

  tempDiv.innerHTML = html;
  tempDiv.style.position = 'absolute';
  tempDiv.style.visibility = 'hidden';
  tempDiv.style.height = 'auto';
  tempDiv.style.width = `${isFullText ? fullTextWidth : halfTextWidth}px`;
  tempDiv.style.whiteSpace = 'normal';
  tempDiv.style.overflowWrap = 'break-word';

  document.body.appendChild(tempDiv);

  const height = tempDiv.offsetHeight;

  document.body.removeChild(tempDiv);

  return {
    height,
    element: tempDiv,
  };
};
