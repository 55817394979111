interface IUserTableHeader {
  id: string;
  label: string;
  width: string;
  align: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}

export interface IUserTableContent {
  userId: string;
  email: string;
  isAdmin: boolean;
  userFullName: string;
  firstName: string;
  lastName: string;
  dateCreated: string;
  publishedQty: number;
  publicQty: number;
  privateQty: number;
  userStatus: string;
  quiltsQty: number;
  lockedQty: number;
  deletedQty: number;
  draftQty: number;
  locked: boolean;
  emailConfirmed: boolean;
  deleted: boolean;
  reasonForDelete?: string;
  reasonForLock?: string;
}

export const UserTableHeader: IUserTableHeader[] = [
  {
    id: 'button',
    label: 'More',
    width: 'auto',
    align: 'center',
  },
  {
    id: 'firstName',
    label: 'First Name',
    width: 'auto',
    align: 'center',
  },
  {
    id: 'lastName',
    label: 'Last Name',
    width: 'auto',
    align: 'center',
  },
  {
    id: 'email',
    label: 'Email',
    width: 'auto',
    align: 'center',
  },
  {
    id: 'dateCreated',
    label: 'Account created',
    width: 'auto',
    align: 'center'
  },
  {
    id: 'userStatus',
    label: 'User Status',
    width: 'auto',
    align: 'center'
  },
  {
    id: 'quiltsQty',
    label: 'Total Quilts',
    width: 'auto',
    align: 'center',
  },
  {
    id: 'publicQty',
    label: 'Public',
    width: 'auto',
    align: 'center',
  },
  {
    id: 'privateQty',
    label: 'Private',
    width: 'auto',
    align: 'center',
  },
  {
    id: 'publishedQty',
    label: 'Published',
    width: 'auto',
    align: 'center',
  },
  {
    id: 'draftQty',
    label: 'Drafts',
    width: 'auto',
    align: 'center',
  },
  {
    id: 'lockedQty',
    label: 'Locked',
    width: 'auto',
    align: 'center',
  },
  {
    id: 'deletedQty',
    label: 'Deleted',
    width: 'auto',
    align: 'center',
  },
];

export const SortBy = {
  ASC: 'asc',
  DESC: 'desc',
} as const;

export const UserSortField = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  DATE_CREATED: 'dateCreated',
  USER_STATUS: 'userStatus'
} as const;

export const userSortFields = [
  UserSortField.DATE_CREATED,
  UserSortField.EMAIL,
  UserSortField.USER_STATUS,
  UserSortField.LAST_NAME,
  UserSortField.FIRST_NAME
]

export const UserStatus = {
  ACTIVE: 'Active',
  UNREGISTERED: 'Unregistered',
  UNVERIFIED: 'Unverified',
  LOCKED: 'Locked',
  DELETED: 'Deleted'
}
