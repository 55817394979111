const CLOUD_IMAGE_URL = 'https://cvhmemorialquilt.blob.core.windows.net/quilt-images/thumbnails/full';

const EDITED_IMAGE = 'edited-img-';

const STEP_ONE_SNAPSHOT_KEY = 'CHQStepOne';
const STEP_TWO_SNAPSHOT_KEY = 'CHQStepTwo';
const STEP_THREE_SNAPSHOT_KEY = 'CHQStepThree';
const STEP_FOUR_SNAPSHOT_KEY = 'CHQStepFour';

const AUTOSAVE_INTERVAL = 1000 * 60;

export {
    CLOUD_IMAGE_URL,
    EDITED_IMAGE,
    STEP_ONE_SNAPSHOT_KEY,
    STEP_TWO_SNAPSHOT_KEY,
    STEP_THREE_SNAPSHOT_KEY,
    STEP_FOUR_SNAPSHOT_KEY,
    AUTOSAVE_INTERVAL
}
