import heic2any from "heic2any";

const heicToJpeg = async (file: File): Promise<File | null> => {
  if (file) {
    try {
      const convertedBlob = await heic2any({
        blob: file,
        toType: 'image/jpeg',
      }) as Blob;

      return new File([convertedBlob], file.name.replace('.heic', '.jpeg'), { type: convertedBlob.type });
    } catch (error) {
      console.error('Error during conversion:', error);
    }
  } 

  return null;
};

export { heicToJpeg };
