import { useTranslates } from "providers/translates";
import { IconLinks } from "../IconLinks";
import { useRedirectAndScroll } from "hooks";
import { useAuth } from "providers/auth-provider";
import styles from './styles.module.scss';
import '../../header.scss';

type Props = {
  headerItemsController: {
    language: boolean;
    howToUse: boolean;
    support: boolean;
    myAccount: boolean;
    donate: boolean;
    aboutUs: boolean;
    contactUs: boolean;
    icons: boolean;
  },
  actionLogout: () => void;
}

const DesktopMenu: React.FC<Props> = ({
  headerItemsController,
  actionLogout
}) => {
  const { selectedLang, setSelectedLang, translates } = useTranslates();
  const { onlyRedirect } = useRedirectAndScroll({});
  const { isAuthorized, user } = useAuth();

  return (
    <div className={styles['header__info']}>
      {/* {headerItemsController.language && (
        <div className={"header__lang"}>
          <span
            className="-active"
            onClick={() => setSelectedLang(selectedLang === 'en-CA' ? 'fr-CA' : 'en-CA')}
          >
            {selectedLang === 'en-CA' ? 'English' : 'Français'}
          </span>
        </div>
      )} */}

      {headerItemsController.aboutUs && (
        <div 
          className="header__link hidden" 
          onClick={() => onlyRedirect('/about')}
          style={{borderLeft: 'none'}}
        >
          <span>{translates?.header?.about || 'About'}</span>
        </div>
      )}

      {headerItemsController.contactUs && (
        <a
          className="header__link hidden"
          target={'_blank'}
          rel="noopener noreferrer"
          href="mailto:info@virtualhospice.ca?subject=I have questions about the MemorialQuilt"
        >
          <span>{translates?.header?.contact || 'Contact Us'}</span>
        </a>
      )}

      {headerItemsController.howToUse && (
        <div 
          className="header__link hidden" 
          onClick={() => onlyRedirect('/howtouse')}
        >
          <span>{'How To Use'}</span>
        </div>
      )}

      {headerItemsController.support && (
        <div 
          className="header__link hidden" 
          onClick={() => onlyRedirect('/resources')}
        >
          <span>{'Resources'}</span>
        </div>
      )}

      {isAuthorized && headerItemsController.myAccount && (
        <div 
          className="header__link hidden" 
          onClick={() => onlyRedirect('/profile')}
        >
          <span>{'My Account'}</span>
        </div>
      )}

      {isAuthorized && !user?.isInvitedUser ? (
        <div 
          className="header__link hidden" 
          onClick={actionLogout}
        >
          <span>{translates?.header?.logout || 'Logout'}</span>
        </div>
      ) : (
        <div 
          className="header__link hidden" 
          onClick={() => onlyRedirect('/login')}
        >
          <span>{translates?.header?.signIn || 'Sign In'}</span>
        </div>
      )}

      {headerItemsController.icons && (
        <IconLinks />
      )}
    </div>
  );
}

export { DesktopMenu };
