import { FC } from 'react';
import { QuiltsResponseMega } from '../../../../api/http-client/types';
import WithTooltipWrap from '../WithTooltipWrap';
import styles from '../../styles.module.scss';

interface CarouselItemTypeModel {
  isStub?: boolean;
  item?: QuiltsResponseMega;
}

export const CarouselItemType: FC<CarouselItemTypeModel> = ({ isStub, item }) => {
  return isStub ? (
    <div className={styles.carousel__content_item}></div>
  ) : (
    <>
      {item && (
        <WithTooltipWrap
          children={
            <div className={styles.carousel__content_item}>
              {item && (
                <img
                  src={item?.finalImage?.full || '/img/general/dummy-person.png'}
                  alt={item.name}
                />
              )}
            </div>
          }
          id={item?.id}
          url={item?.finalImage?.full || '/img/general/dummy-person.png'}
          name={item?.name || `${item?.firstName} ${item?.lastName}`}
          dateOfBirth={item?.dateOfBirth?.year || 1960}
          dateOfPassing={item?.dateOfPassing?.year || 1960}
        ></WithTooltipWrap>
      )}
    </>
  );
};
