import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import { AuthProvider } from './providers/auth-provider';
import { TranslatesProvider } from './providers/translates';
import { NotifyContext } from './components/NotifyContext/NotifyContext';
import { colors } from 'utilities';
import { NotifyNew } from 'components/NotifyNew';
import 'drag-drop-touch';

import './index.scss';
import './normalize.scss';
import { HelmetProvider } from 'react-helmet-async';

const theme = createTheme({
  palette: {
    primary: {
      light: colors.blue,
      main: colors.dark_blue,
    },
    secondary: {
      light: colors.grey,
      main: colors.dark_grey,
    },
    error: {
      main: colors.red,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Provider store={store}>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <TranslatesProvider>
              <App />
              <NotifyContext />
              <NotifyNew />
            </TranslatesProvider>
          </ThemeProvider>
        </AuthProvider>
      </Provider>
    </BrowserRouter>
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
