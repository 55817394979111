import { useState } from "react";
import TruncateMarkup from "react-truncate-markup";
import styles from './styles.module.scss';
import parse from "html-react-parser";
import { getArtificialBlockSetting } from "components/CreateMemorialQuiltComponents/DialogBlocks/utils";

type Props = {
  isTopContent?: boolean;
  isFullText?: boolean;
  content: string;
}

export const VISIBLE_HEIGHT = 250;

const ReadMore: React.FC<Props> = ({
  isTopContent,
  isFullText = false,
  content
}) => {
  const [showSettings, setShowSettings] = useState(() => {
    if (getArtificialBlockSetting(content, isFullText).height >= VISIBLE_HEIGHT) {
      return{
        isShowReadLess: true,
        isShow: true,
      };
    }

    return {
      isShowReadLess: false,
      isShow: false,
    }
  });

  return (
    <div className={styles.textBlock}>
      <TruncateMarkup 
        lines={showSettings.isShowReadLess ? 10 : 1000}
        ellipsis=""
      >
        <div
          className={isTopContent ? '' : `${styles.textBlock__content} textForPrint`}
          style={{
            wordBreak: 'break-word',
            overflowWrap: 'break-word',
            whiteSpace: 'normal'
          }}
        >{parse(content)}</div>
      </TruncateMarkup>
      {showSettings.isShow && (
        <div
          className={styles.read}
          onClick={() => setShowSettings(prev => ({...prev, isShowReadLess: !prev.isShowReadLess}))}
        >
          {showSettings.isShowReadLess ? 'Read more' : 'Show less'}
        </div>
      )}
    </div>
  );
}

export { ReadMore };
