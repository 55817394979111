import { ContributorStatus } from "common/enums/admin-dashboard/contributor-status.enum";

export const getStatusForContributor = (status: string) => {
  switch(status) {
    case ContributorStatus.CONTRIBUTED:
      return 'Contributions Made';
    case ContributorStatus.DELETED:
      return 'User Deleted';
    case ContributorStatus.LOCKED:
      return 'User Locked';
    default:
      return 'No Contributions';
  }
};