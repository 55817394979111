import React from 'react';
import Faq from '../pages/Faq';
import Memory from '../pages/Memory';
import Resources from '../pages/Resources';
import TakeOurSurvey from '../pages/TakeOurSurvey';
import Home from '../pages/Home';
import Login from '../pages/Login';
import SearchQuilt from '../pages/SearchQuilt';
import SignUp from '../pages/SignUp';
import { ResetPassword } from '../pages/ResetPassword';
import CreateMemorialQuilt from '../pages/CreateMemorialQuilt';
import AddImagesOnly from '../pages/AddImagesOnly';
import About from '../pages/About';
import HowToUse from '../pages/HowToUse';
import QuiltView from '../pages/QuiltView';
import { UserProfile } from 'pages/UserProfile';
import RedirectToUpdate from '../pages/RedirectToUpdate';

interface IRoute {
  path: string;
  element: React.ComponentType;
  index?: boolean;
  isPrivate?: boolean;
}

export enum RoutesName {
  Home = '',
  Memory = 'memory/:id',
  QuiltView = 'quilts/:id/memorial',
  UserProfile = 'profile',
  CreateQuilt = 'create',
  UpdateQuilt = 'update/:id',
  SearchQuilt = 'search',
  Faq = 'faq',
  Resources = 'resources',
  TakeOurSurvey = 'take-our-survey',
  Login = 'login',
  SignUp = 'signup',
  ResetPassword = 'reset',
  RedirectToUpdate = 'update-invite/:id',
  About = 'about',
  HowToUse = 'howtouse',
  AddImagesOnly = 'add-images/:id',
}

export const routs: IRoute[] = [
  {
    path: RoutesName.Home,
    index: true,
    element: Home,
  },
  {
    path: RoutesName.QuiltView,
    element: QuiltView,
  },
  {
    path: RoutesName.UserProfile,
    element: UserProfile,
    isPrivate: true,
  },
  {
    path: RoutesName.CreateQuilt,
    element: CreateMemorialQuilt,
    isPrivate: true,
  },
  {
    path: RoutesName.UpdateQuilt,
    element: CreateMemorialQuilt,
    isPrivate: true,
  },
  {
    path: RoutesName.SearchQuilt,
    element: SearchQuilt,
  },
  {
    path: RoutesName.Faq,
    element: Faq,
  },
  {
    path: RoutesName.Resources,
    element: Resources,
  },
  {
    path: RoutesName.TakeOurSurvey,
    element: TakeOurSurvey,
  },
  {
    path: RoutesName.SignUp,
    element: SignUp,
  },
  {
    path: RoutesName.Login,
    element: Login,
  },
  {
    path: RoutesName.Memory,
    element: Memory,
  },
  {
    path: RoutesName.ResetPassword,
    element: ResetPassword,
  },
  {
    path: RoutesName.AddImagesOnly,
    element: AddImagesOnly,
  },
  {
    path: RoutesName.HowToUse,
    element: HowToUse,
  },
  {
    path: RoutesName.About,
    element: About,
  },
  {
    path: RoutesName.RedirectToUpdate,
    element: RedirectToUpdate,
  },
];
