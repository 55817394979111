import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styles from '../About/styles.module.scss';
import { useRedirectAndScroll } from 'hooks';
import { Link } from 'react-router-dom';

const HowToUsePage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { onlyScroll } = useRedirectAndScroll({});

  useEffect(() => {
    const scrollTo = searchParams?.get('visible');
    if (scrollTo) {
      onlyScroll(scrollTo);
    }
  }, []);

  return (
    <section className="center">
      <h1 className={styles.titleH1}>How To Use</h1>

      <h2 className={styles.titleH2}>Creating a memorial quilt square</h2>
      <p className={styles.bodyText}>
        You can use a computer, laptop, tablet, or smartphone to create your own memorial quilt square. 
        Creating your quilt square is personal, and you can take as much time as you need. 
      </p>
      <ul className='colorNavy'>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>Select “Create a memorial” to get started.
        </li>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>You will be asked for your full name, email address, and postal code to create an account. 
        </li>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>You will be guided through step-by-step instructions to
        </li>
      </ul>
      <ul className={styles.nestedList}>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>Create the memorial by adding photos and text, and by positioning your content.
        </li>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>Invite others to collaborate on the memorial.
        </li>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>Allow viewers to add tributes and condolences.
        </li>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>Share the memorial on the Canadian Healing Quilt or keep it private.
        </li>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>Share the memorial with friends, families, colleagues, or others.
        </li>
      </ul>

      <h2 className={styles.titleH2}>Tributes and condolences</h2>
      <p className={styles.bodyText}>
        If you are the creator of a memorial, you can choose to allow viewers to add tributes or condolences or 
        to light a candle. You can also choose to delete any tributes or condolences. 
      </p>
      <p className={styles.bodyText}>
        If you are viewing a memorial, you can add a message or icon, or light a candle to show support. There 
        is no cost for this.
      </p>

      <h2 className={styles.titleH2}>Search for a memorial quilt square</h2>
      <p className={styles.bodyText}>
        Locate a memorial by using the search feature on the home page and entering the person’s name. 
      </p>

      <h2 className={styles.titleH2}>Need some inspiration?</h2>
      <p className={styles.bodyText}>
        Click{' '}
        <Link 
          className={styles.customLink}
          to={''} 
          target="__blank" 
          rel="noreferrer"
        >
          here
        </Link>
        {' '}to see some examples of completed quilt squares. 
      </p>

      <h2 className={styles.titleH2}>Grief support</h2>
      <p className={styles.bodyText}>
        For assistance with grief, for yourself or those you care about, visit the support resources on our “
        <Link 
          className={styles.customLink}
          to={'/resources/?visible=grief-support'} 
          target="__blank" 
          rel="noreferrer"
        >
          Resources
        </Link>
        ” page.
      </p>

      <h2 className={styles.titleH2}>User support</h2>
      <p className={styles.bodyText}>
        We have tried to ensure you have a positive experience, but technical difficulties can occur. If you have trouble using the 
        Canadian Healing Quilt, please refer to the “Frequently asked questions” below. If you need further support, email{' '}
        <a 
          href="mailto:info@virtualhospice.ca?subject=I have questions about the Canadian Healing Quilt"
          className={styles.customLink}
        >
          info@virtualhospice.ca
        </a>
        .
      </p>

      <h2 className={styles.titleH2}>Frequently asked questions</h2>
      <h3 className={styles.titleH3}>I didn’t get a confirmation email when I signed up. What should I do?</h3>
      <p className={styles.bodyText}>
        The Canadian Healing Quilt automatically sends a confirmation email to the email address you used to make 
        your account. Check your “Junk” or “Spam” folder for the confirmation email to verify your account. If 
        you still need help, email us at{' '}
        <a 
          href="mailto:info@virtualhospice.ca?subject=I have questions about the Canadian Healing Quilt"
          className={styles.customLink}
        >
          info@virtualhospice.ca
        </a>
        .
      </p>
      <h3 className={styles.titleH3}>Is there a cost for creating a memorial?</h3>
      <p className={styles.bodyText}>
        No. The Canadian Healing Quilt is completely free for Canadians. There are no hidden fees and no options for costly services. No one will ever try to sell you anything.
      </p>
      <h3 className={styles.titleH3}>Can I use a smartphone or tablet?</h3>
      <p className={styles.bodyText}>
        Yes. The Canadian Healing Quilt is compatible with all platforms using any browser (e.g., Chrome, Safari). You can use it on smartphones, tablets, or personal computers.
      </p>
      <h3 className={styles.titleH3}>What if I still have questions?</h3>
      <p className={styles.bodyText}>
        If you have questions, email us at{' '}
        <a 
          href="mailto:info@virtualhospice.ca?subject=I have questions about the Canadian Healing Quilt"
          className={styles.customLink}
        >
          info@virtualhospice.ca
        </a>
        .
      </p>
      <h3 className={styles.titleH3}>What if I have technical issues?</h3>
      <p className={styles.bodyText}>
        If you have trouble accessing the Canadian Healing Quilt, logging in, or using any of the features, please email us at{' '}
        <a 
          href="mailto:info@virtualhospice.ca?subject=I have questions about the Canadian Healing Quilt"
          className={styles.customLink}
        >
          info@virtualhospice.ca
        </a>
        .
      </p>
      <h3 className={styles.titleH3}>How can I help support this project?</h3>
      <p className={styles.bodyText}>
        To help us maintain the HealingQuilt.ca, donate here.
      </p>
      <p className={styles.bodyText}>
        We welcome new partnerships and opportunities to expand the awareness 
        and use of the Canadian Healing Quilt across Canada. To learn more or get involved, email{' '}
        <a 
          href="mailto:info@virtualhospice.ca?subject=I have questions about the Canadian Healing Quilt"
          className={styles.customLink}
        >
          info@virtualhospice.ca
        </a>
        .
      </p>
    </section>
  );
};

export default HowToUsePage;
